import React, { Component } from "react";
import { Link } from "react-router-dom";

//Redux
import * as Actions from "../redux/actions";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";

//Components
import ImageInput from "../components/imagesuploader/ImageInput";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import FileCopy from "@material-ui/icons/FileCopy";
import StorefrontOutlinedIcon from "@material-ui/icons/StorefrontOutlined";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Spinner from "../components/spinner/spinner";
import { Icon } from "@material-ui/core";

import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-places-autocomplete";

class CreazioneStore extends Component {
    constructor(props) {
        super(props);
        this.account = null;
        this.storeAddress = null;

        this.state = {
            showLoader: false,
            form: {
                name: "",
                adddress: "",
            },
            user: {},
        };
    }

    generateNewWallet = () => {
        this.props.walletCreate();
    };

    createStore = (name, location, phone, img, ticker) => {
        this.props.storeCreate({ name, location, phone, img, ticker });
    };

    // createStore = (nome, indirizzo, ticker) => {
    //   this.props.storeCreate(nome, indirizzo, ticker);
    // };

    render() {
        return (
            <div className="creazione-store">
                <HorizontalLinearStepper
                    onChangeTutorial={this.props.onChangeTutorial}
                    walletMnemonic={this.props.user.mnemonic}
                    createStore={this.createStore}
                    generateNewWallet={this.generateNewWallet}
                    loading={this.props.loading}
                    startTutorial={this.props.startTutorial}
                />
                <Spinner show={this.props.loading} additionalText={true} />
            </div>
        );
    }
}

const mapStateToProps = ({ general }) => ({
    user: general.user,
    loading: general.loading,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CreazioneStore);

function HorizontalLinearStepper(props) {
    const { startTutorial } = props;

    const mnemonic = useSelector(state => state.general.user.mnemonic);
    const userAddress = useSelector(state => state.general.user.address);
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [values, setValues] = React.useState({
        name: "",
        address: "",
        phone: "",
        ticker: "",
        img: "",
        formattedImg: "",
        error: "",
        errorImg: "",
    });
    const [showSnackbar, setShowSnackbar] = React.useState(false);

    const handleChange = prop => event => {
        setValues({ ...values, [prop]: event.target.value, error: "" });
    };

    const handleSelect = address => {
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => console.log("Success", latLng))
            .catch(error => console.error("Error", error));
    };

    const handleImageSelection = i => {
        setValues({ ...values, img: i, errorImg: "" });
    };

    function getStepContent(step) {
        switch (step) {
            case 0:
                return (
                    <div className="step-0 intro">
                        <h3>
                            Inizia la creazione dell'account per la tua
                            attività. <br /> Ti seguiremo nel processo!
                        </h3>
                    </div>
                );
            case 1:
                return (
                    <div>
                        <h3>Inserisci i dati della tua attivià</h3>
                        <div className="step-1 inputs-wrapper">
                            <TextField
                                id="name"
                                label="Ragione Sociale"
                                type="text"
                                color="secondary"
                                placeholder={"Alimentari Santini"}
                                className="input"
                                variant="outlined"
                                value={values.name}
                                onChange={handleChange("name")}
                            />
                            <TextField
                                id="address"
                                color="secondary"
                                label="Indirizzo attività"
                                type="text"
                                placeholder={"Via Sant'Antonino "}
                                className="input"
                                value={values.address}
                                variant="outlined"
                                onChange={handleChange("address")}
                            />
                            {/* <PlacesAutocomplete
                value={values.address}
                onChange={(value) => {
                  console.log(value);
                  return setValues({ ...values, address: value });
                }}
                onSelect={handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => {
                  return (
                    <>
                      <TextField
                        id="address"
                        color="secondary"
                        label="Indirizzo attività"
                        type="text"
                        placeholder={"Via Sant'Antonino "}
                        className="input"
                        value={values.address}
                        variant="outlined"
                        inputProps={{
                          autoComplete: "new-password",
                          form: {
                            autoComplete: "off",
                          },
                        }}
                        {...getInputProps({
                          placeholder: "Search Places ...",
                          className: "input",
                        })}
                      />
                      <div
                        className="autocomplete-dropdown-container"
                        style={{ borderWidth: 1, borderColor: "#ff0000" }}
                      >
                        {loading && <div>Loading...</div>}
                        {[
                          {
                            active: false,
                            description: "Via del Corso, Roma, RM, Italia",
                            formattedSuggestion: {
                              mainText: "Via del Corso",
                              secondaryText: "Roma, RM, Italia",
                            },
                            id: undefined,
                            index: 0,
                            matchedSubstrings: [{ length: 3, offset: 0 }],

                            placeId:
                              "Eh9WaWEgZGVsIENvcnNvLCBSb21hLCBSTSwgSXRhbGlhIi4qLAoUChIJrw5W9FNgLxMRUd2R7kki3JISFAoSCbuOkvmWYS8TEThuZZMGdw-5",
                            terms: [
                              { offset: 0, value: "Via del Corso" },
                              { offset: 15, value: "Roma" },
                              { offset: 21, value: "RM" },
                              { offset: 25, value: "Italia" },
                            ],

                            types: ["route", "geocode"],
                          },
                          {
                            active: false,
                            description: "Via del Corso, Roma, RM, Italia",
                            formattedSuggestion: {
                              mainText: "Via del Corso",
                              secondaryText: "Roma, RM, Italia",
                            },
                            id: undefined,
                            index: 0,
                            matchedSubstrings: [{ length: 3, offset: 0 }],

                            placeId:
                              "Eh9WaWEgZGVsIENvcnNvLCBSb21hLCBSTSwgSXRhbGlhIi4qLAoUChIJrw5W9FNgLxMRUd2R7kki3JISFAoSCbuOkvmWYS8TEThuZZMGdw-5",
                            terms: [
                              { offset: 0, value: "Via del Corso" },
                              { offset: 15, value: "Roma" },
                              { offset: 21, value: "RM" },
                              { offset: 25, value: "Italia" },
                            ],

                            types: ["route", "geocode"],
                          },
                        ].map((suggestion) => (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className: "",
                              style: { borderWidth: 1, borderColor: "#ff0000" },
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        ))}
                      </div>
                    </>
                  );
                }}
              </PlacesAutocomplete> */}
                            <TextField
                                id="phone"
                                color="secondary"
                                label="Telefono"
                                type="number"
                                placeholder={"0212454510"}
                                className="input"
                                value={values.phone}
                                variant="outlined"
                                onChange={handleChange("phone")}
                            />

                            <TextField
                                id="ticker"
                                color="secondary"
                                label="Scegli un codice token"
                                type="text"
                                placeholder={"PRGU"}
                                className="input"
                                value={values.ticker}
                                variant="outlined"
                                inputProps={{ maxLength: 5 }}
                                onChange={handleChange("ticker")}
                            />
                            {values.error && (
                                <span className="error">{values.error}</span>
                            )}
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className="step-2">
                        <h3>Imposta un immagine del tuo negozio</h3>
                        <div className="image-wrapper">
                            {values.img ? (
                                <img src={values.img} />
                            ) : (
                                <Icon>
                                    <StorefrontOutlinedIcon />
                                </Icon>
                            )}
                        </div>
                        <ImageInput setFile={handleImageSelection} />
                        <span className="error">{values.errorImg}</span>
                    </div>
                );

            case 3:
                return (
                    <div>
                        <h3>Abbiamo creato il tuo account!</h3>
                        <p>
                            Conserva la tua frase mnemonica per poterti
                            ricollegare nuovamente:
                        </p>
                        <TextField
                            className="mnemonic-holder"
                            type="text"
                            variant="outlined"
                            id="save-mnemonic"
                            color="secondary"
                            rows={4}
                            multiline
                            defaultValue={mnemonic.phrase}
                            readOnly
                            InputProps={{
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() =>
                                                copyToClipboard(mnemonic.phrase)
                                            }
                                            edge="end"
                                        >
                                            <FileCopy />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        ></TextField>
                        <p>
                            Conferma per aggiungere <b>"{values.name}"</b> in{" "}
                            <b>"{values.address}"</b> nella piattaforma
                        </p>
                    </div>
                );
            default:
                return "Loading";
        }
    }

    const steps = ["Inizio", "Dati attività", "Foto del negozio", "Conferma"];

    const isStepSkipped = step => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        if (
            activeStep === 1 &&
            (!values.address || !values.name || !values.ticker || !values.phone)
        ) {
            setValues({
                ...values,
                error: "Compila tutti i campi prima di procedere",
            });

            return;
        } else if (activeStep === 1) {
            props.generateNewWallet();
        } else if (activeStep === 2) {
            if (!values.img) {
                setValues({
                    ...values,
                    errorImg: "Inserisci prima una foto",
                });
                return;
            }
        } else if (activeStep === 3) {
            //Convert image to base64
            toDataURL(values.img, res =>
                props.createStore(
                    values.name,
                    values.address,
                    values.phone,
                    res,
                    values.ticker,
                ),
            );
        }
        // props.onChangeTutorial();
        // props.onChangeTutorial({ action: "restart" });
        setActiveStep(prevActiveStep => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        // props.onChangeTutorial();

        // props.onChangeTutorial({ action: "prev" });
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    //Given an image url converts it to base64
    function toDataURL(url, callback) {
        // console.log("tdu: ", url);
        let xhr = new XMLHttpRequest();

        xhr.onload = function () {
            let reader = new FileReader();

            // console.log("tdu: ", xhr.response);

            reader.readAsDataURL(xhr.response);
            reader.onloadend = function (e) {
                callback(e.target.result);
            };
        };
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.send();
    }

    const copyToClipboard = str => {
        console.log(str);
        const el = document.createElement("textarea");
        el.value = str;
        el.setAttribute("readonly", "");
        el.style.position = "absolute";
        el.style.left = "-9999px";
        document.body.appendChild(el);
        const selected =
            document.getSelection().rangeCount > 0
                ? document.getSelection().getRangeAt(0)
                : false;
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        if (selected) {
            document.getSelection().removeAllRanges();
            document.getSelection().addRange(selected);
        }
        setShowSnackbar(true);
    };

    return (
        <div className="steps-wrapper">
            {activeStep !== steps.length && (
                <Stepper
                    alternativeLabel
                    activeStep={activeStep}
                    className="stepper"
                >
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            )}
            <div className="step-wrapper">
                {activeStep === steps.length && !props.loading && (
                    <div className="final-step">
                        <div>
                            <h3>
                                Creazione del negozio avvenuta con successo!
                            </h3>
                            <p>
                                Il tuo account e il tuo negozio virtuale sono
                                stati creati con successo. Ora puoi generare i
                                tuoi primi token prenotazione e iniziare a
                                ricevere prenotazioni!
                            </p>
                        </div>

                        <div className="buttons-wrapper">
                            <Link
                                to={`/gestione-token`}
                                onClick={startTutorial}
                            >
                                <Button
                                    disableElevation
                                    variant="contained"
                                    color="secondary"
                                >
                                    GENERA
                                </Button>
                            </Link>
                        </div>
                    </div>
                )}
                {activeStep !== steps.length && (
                    <div style={{ height: "100%" }}>
                        <div className="step-content">
                            {getStepContent(activeStep)}
                        </div>
                        <div className="buttons-wrapper">
                            {activeStep !== 0 ? (
                                <Button
                                    onClick={handleBack}
                                    variant="outlined"
                                    color="primary"
                                >
                                    Indietro
                                </Button>
                            ) : (
                                <Link to={`/login`}>
                                    <Button
                                        disableElevation
                                        variant="outlined"
                                        color="primary"
                                    >
                                        Torna al login
                                    </Button>
                                </Link>
                            )}

                            <Button
                                disableElevation
                                variant="contained"
                                color="secondary"
                                onClick={handleNext}
                            >
                                {activeStep === steps.length - 1
                                    ? "Conferma"
                                    : "Avanti"}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            <Snackbar
                open={showSnackbar}
                autoHideDuration={2000}
                onClose={() => {
                    setShowSnackbar(false);
                }}
                style={{ position: "absolute", bottom: "70px" }}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={() => {
                        setShowSnackbar(false);
                    }}
                    severity="success"
                >
                    Frase mnemonica copiata!
                </Alert>
            </Snackbar>
        </div>
    );
}
