import { createMuiTheme } from "@material-ui/core/styles";

import variables from "./style/variables.scss";

export const main = createMuiTheme({
  palette: {
    primary: {
      main: "#1d1d1b",
    },
    secondary: {
      main: "#b8d04e",
    },
  },
});
