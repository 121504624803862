import { walletConstants } from "../redux/constants/wallet.constants";
import ipfs from "ipfs";
import { ethers } from "ethers";
import { nanoid } from "nanoid";

export var ipfsNode = null;
export let provider = null;

export const createIpfsEnhancer =
    () =>
    createStore =>
    (...args) => {
        const store = createStore(...args);
        provider = new ethers.providers.JsonRpcProvider(
            "https://polygon-mumbai.infura.io/v3/98084ec8ac4d49e181f0ffc83562f6f6",
        );

        ipfs.create({ repo: "ok" + nanoid() })
            .then(node => {
                ipfsNode = node;

                node.bootstrap
                    .add(
                        "/ip4/213.136.90.96/tcp/4001/ipfs/QmeMTajwQLn7JruhJXZshdEdYNPTjRtEJXvDJUa7FRDbLU",
                    )
                    .then(() =>
                        store.dispatch({
                            type: walletConstants.CREATE_NODE,
                            payload: true,
                        }),
                    );
            })
            .catch(e => console.log(e));

        return store;
    };
