import React from "react";

import "./style.scss";

import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import Button from "@material-ui/core/Button";

export default function ImageInput(props) {
  function handleChange(event) {
    if (event.target.files.length) {
      props.setFile(URL.createObjectURL(event.target.files[0]));
    }
  }

  return (
    <div className="image-input">
      <div className="buttons-wrapper">
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="contained-button-file"
          onChange={handleChange}
          type="file"
        />
        <label htmlFor="contained-button-file" className="select">
          <Button
            variant="contained"
            color="primary"
            component="span"
            disableElevation
          >
            Scegli una foto
          </Button>
        </label>
        <input
          accept="image/*"
          capture="environment"
          style={{ display: "none" }}
          onChange={handleChange}
          id="icon-button-file"
          type="file"
        />
        <label htmlFor="icon-button-file" className="take">
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
          >
            <PhotoCamera />
          </IconButton>
        </label>
      </div>
    </div>
  );
}
