import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { isEmpty } from "lodash";

import { routes } from "./config/routes";

import * as Actions from "./redux/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { ethers } from "ethers";

import "./style/App.scss";

import Header from "./components/header/header";
import Drawer from "./components/drawer/drawer";
import ReactJoyride from "react-joyride";

import { requestFunds } from "./config/utility";

import {
    createIpfsEnhancer,
    ipfsNode,
    provider,
} from "./enhancers/createIpfsEnhancer";

const TOUR_STEPS = [
    // {
    //   target: ".step-0",
    //   content: "Apri il menù e seleziona gestione token",
    //   disableBeacon: true,
    //   spotlightClicks: true,
    //   hideNextButton: true,
    // },
    {
        target: ".step-1",
        content:
            "Inserisci l'intervallo di date in cui vuoi creare i tuoi token e clicca genera nuovi token",
        disableBeacon: true,
        hideBackButton: true,
    },
    {
        target: ".step-2",
        content:
            "Inserisci l'intervallo di orari, se fai la pausa pranzo e quanto vuoi che durino i tuoi token! Attenzione, non puoi creare più di 25 slot (solo per la prima creazione).",
        disableBeacon: true,
        hideBackButton: true,
    },
    {
        target: ".step-0",
        content:
            "Apri il menù e seleziona Vista calendario per vedere i tuoi slot, potrebbe volerci qualche minuto per la creazione.",
        disableBeacon: true,
        spotlightClicks: true,
        hideBackButton: true,
        hideNextButton: true,
    },
];

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isListening: false,
            isDrawerOpen: false,
            joyride: ReactJoyride,
        };
    }

    componentWillMount() {
        if (this.props.user.address && this.props.userStore.address) {
            this.handleListeners();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.user.address &&
            this.props.userStore.address &&
            !this.state.isListening
        ) {
            this.handleListeners();
        }
        if (!ipfsNode) {
            createIpfsEnhancer();
        }
    }

    componentWillUnmount() {
        this.props.userStore.removeAllListeners();
        this.setState({ isListening: false });
    }

    async componentDidMount() {
        if (this.props.user.address) {
            let interval = setInterval(async () => {
                try {
                    let balance = parseFloat(
                        ethers.utils
                            .formatEther(
                                await this.props.user
                                    .connect(provider)
                                    .getBalance(),
                            )
                            .match(/^[0-9]*.[0-9][0-9]/)[0],
                    );
                    console.log("Current balance...", balance);

                    if (balance < 0.5) {
                        console.log("Request funds...");
                        requestFunds(this.props.user.address);
                    } else {
                        console.log("Stop requesting funds..");
                        clearInterval(interval);
                    }
                } catch (e) {
                    console.log(e);
                }
            }, 120000);
        }
    }

    handleListeners = async () => {
        this.setState({ isListening: true });

        const filterFromMe = this.props.userStore.filters.Transfer(
            this.props.user.address,
        );
        const filterToMe = this.props.userStore.filters.Transfer(
            null,
            this.props.user.address,
        );

        const transfersFromMeListener = this.props.userStore.on(
            filterFromMe,
            async (fromAddress, toAddress, value, event) => {
                this.toggleListening(true);
                this.props.getSingleToken(
                    (await event.args[2]).toString(),
                    this.props.userStore,
                    this.props.user.address,
                );
            },
        );

        const transfersToMeListeners = this.props.userStore.on(
            filterToMe,
            async (fromAddress, toAddress, value, event) => {
                this.toggleListening(true);
                this.props.getSingleToken(
                    (await event.args[2]).toString(),
                    this.props.userStore,
                    this.props.user.address,
                );
            },
        );
    };

    toggleDrawer = s => {
        this.setState({ isDrawerOpen: s });
    };

    toggleListening = s => {
        this.setState({ isListening: s });
    };

    callback = data => {
        const { action, index, type, status } = data;
        // console.log(data);
        // console.log(action, index, type, status);

        if (action === "next") {
            if (status === "running") {
                this.props.nextStopTutorial();
            }
            if (status === "finished") {
                this.props.finishTutorial();
            }
        }
        if (action === "close") {
            this.props.stopTutorial();
        }
        if (action === "stop") {
            this.props.nextStopTutorial();
        }
    };

    render() {
        return (
            <div className="App">
                <Switch>
                    {routes.map((route, i) => {
                        let Component = route.component;
                        return (
                            <Route
                                key={i}
                                exact
                                path={route.path}
                                render={props =>
                                    !route.private ? (
                                        <div className="inner-container">
                                            <ReactJoyride
                                                // {...tourState}
                                                run={this.props.tutorial.run}
                                                callback={this.callback}
                                                locale={{
                                                    last: "Fine",
                                                    skip: "Salta",
                                                    next: "Ok",
                                                    back: "Indietro",
                                                }}
                                                steps={TOUR_STEPS}
                                                continuous={true}
                                                stepIndex={
                                                    this.props.tutorial
                                                        .stepIndex
                                                }
                                                styles={{
                                                    options: {
                                                        textColor: "#1d1d1b",
                                                        width: 500,
                                                        zIndex: 1000,
                                                    },
                                                    buttonNext: {
                                                        display: "none",
                                                        backgroundColor:
                                                            "#b8d04e",
                                                    },
                                                    buttonBack: {
                                                        color: "#1d1d1b",
                                                    },
                                                    spotlight: {
                                                        padding: 0,
                                                        margin: 0,
                                                        borderRadius: 8,
                                                    },
                                                }}
                                            />
                                            <Component {...props} />
                                        </div>
                                    ) : !isEmpty(this.props.user) ? (
                                        <>
                                            {/* {route.private && (
                        <>
                          <Header
                            pageTitle={route.pageTitle}
                            toggleDrawer={this.toggleDrawer}
                            nextStep={this.props.stopTutorial}
                          />
                          <Drawer
                            isDrawerOpen={this.state.isDrawerOpen}
                            toggleDrawer={this.toggleDrawer}
                          />
                        </>
                      )} */}
                                            <div className="inner-container">
                                                {route.private && (
                                                    <Header
                                                        pageTitle={
                                                            route.pageTitle
                                                        }
                                                        toggleDrawer={
                                                            this.toggleDrawer
                                                        }
                                                        nextStep={
                                                            this.props
                                                                .stopTutorial
                                                        }
                                                    />
                                                )}
                                                {route.private && (
                                                    <Drawer
                                                        isDrawerOpen={
                                                            this.state
                                                                .isDrawerOpen
                                                        }
                                                        toggleDrawer={
                                                            this.toggleDrawer
                                                        }
                                                    />
                                                )}

                                                <Component {...props} />

                                                <ReactJoyride
                                                    // {...tourState}
                                                    locale={{
                                                        last: "Fine",
                                                        skip: "Salta",
                                                        next: "Avanti",
                                                        back: "Indietro",
                                                    }}
                                                    steps={TOUR_STEPS}
                                                    run={
                                                        this.props.tutorial.run
                                                    }
                                                    continuous={true}
                                                    callback={this.callback}
                                                    stepIndex={
                                                        this.props.tutorial
                                                            .stepIndex
                                                    }
                                                    styles={{
                                                        options: {
                                                            arrowColor: "#fff",
                                                            backgroundColor:
                                                                "#fff",
                                                            overlayColor:
                                                                "rgba(0, 0, 0, 0.4)",
                                                            primaryColor:
                                                                "#b8d04e",
                                                            textColor:
                                                                "#1d1d1b",
                                                            width: 700,
                                                        },
                                                        buttonNext: {
                                                            display: TOUR_STEPS[
                                                                this.props
                                                                    .tutorial
                                                                    .stepIndex
                                                            ]?.hideNextButton
                                                                ? "none"
                                                                : "unset",
                                                        },
                                                        buttonBack: {
                                                            color: "#1d1d1b",
                                                        },
                                                    }}
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        <Redirect to={"/login"} />
                                    )
                                }
                            />
                        );
                    })}
                </Switch>
            </div>
        );
    }
}

function mapStateToProps({ general, tutorial }) {
    return { user: general.user, userStore: general.userStore, tutorial };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
