import React, { useState, useEffect } from "react";

import * as Actions from "../redux/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import moment from "moment";

import {
    TokenCard,
    TokenDetails,
} from "../components/tokenComponents/TokenComponents";

import { loadTokenToIPFS } from "../config/utility";
import { createIpfsEnhancer } from "../enhancers/createIpfsEnhancer";

const REFERENCE = moment();
var TODAY = moment(REFERENCE).startOf("day");
var TOMORROW = moment(REFERENCE).add(1, "day").startOf("day");
var LAST = moment(REFERENCE).subtract(15, "minute").startOf("minute");
var NOW = moment(REFERENCE).startOf("minute");
var NEXT = moment(REFERENCE).add(15, "minute").startOf("minute");

export function Dashboard(props) {
    const {
        user,
        userStore,
        todayTokens,
        tomorrowTokens,
        nextDaysTokens,
        ipfsNode,
    } = props;

    const [bookedTokens, setBookedTokens] = useState([]);
    const [showTokenDetails, setShowTokenDetails] = useState(false);
    const account = user;

    //TODO: Find a way to check if there's some tokens in queue to be minted and wait for the event listener to fetch them before recalling all data from the blockchain all over again
    useEffect(() => {
        if (ipfsNode) {
            console.log("Uploading token to IPFS");
            loadTokenToIPFS(userStore.address, user.address);
        } else {
            console.log("CALL ENANCHER");
            createIpfsEnhancer();
        }
    }, [ipfsNode]);

    const openDetailsDialog = tokenData => {
        console.log("Let's open the dialog for: ", tokenData);
        setShowTokenDetails(tokenData);
    };

    return (
        <div className="dashboard">
            {/* {this.props.tutorial.firstTime ? (
          <div className="first-access">
            <p className="first-text">Benvenuto in Prenotazioni.</p>
            <p className="first-text">Non sono ancora state effettuate prenotazioni</p>
            <Button
              disableElevation
              variant="contained"
              color="secondary"
              onClick={() => {
                console.log("SONO QUI");
                this.props.startTutorial();
              }}
            >
              AVVIA TUTORIAL
            </Button>
          </div> 
        // ) : (*/}
            <div className="token-cards-container">
                {/* <div className="top-tokens">
            {bookedTokens[0] && (
              <div className="column">
                <h3>Ultimo</h3>
                <TokenCard key="t0" token={bookedTokens[0]} />
              </div>
            )}
            {bookedTokens[1] && (
              <div className="column middle">
                <h3>Attuale</h3>
                <TokenCard key="t1" token={bookedTokens[1]} />
              </div>
            )}
            {bookedTokens[2] && (
              <div className="column">
                <h3>Prossimo</h3>
                <TokenCard key="t2" token={bookedTokens[2]} />
              </div>
            )}
            </div> */}

                <div className="today tokens-wrapper">
                    <h3>Oggi</h3>
                    {todayTokens.length ? (
                        todayTokens.map((token, i) => {
                            if (
                                moment(token.begin).isAfter(moment(), "minute")
                            ) {
                                return (
                                    <TokenCard
                                        handleClick={() =>
                                            openDetailsDialog(token)
                                        }
                                        key={"today" + i}
                                        token={token}
                                        today
                                    />
                                );
                            }
                        })
                    ) : (
                        <p className="no-token">
                            Non ci sono prenotazioni per oggi
                        </p>
                    )}
                </div>
                <div className="tomorrow tokens-wrapper">
                    <h3>Domani</h3>
                    {tomorrowTokens.length ? (
                        tomorrowTokens.map((token, i) => {
                            console.log(token);
                            return (
                                <TokenCard
                                    handleClick={() => openDetailsDialog(token)}
                                    key={"tomorrow" + i}
                                    token={token}
                                />
                            );
                        })
                    ) : (
                        <p className="no-token">
                            Non ci sono prenotazioni per domani
                        </p>
                    )}
                </div>
                <div className="all tokens-wrapper">
                    <h3>Prossimi giorni</h3>
                    {nextDaysTokens.length ? (
                        nextDaysTokens.map((token, i) => {
                            return (
                                <TokenCard
                                    handleClick={() => openDetailsDialog(token)}
                                    key={i}
                                    token={token}
                                />
                            );
                        })
                    ) : (
                        <p className="no-token">
                            Non ci sono prenotazioni nei prossimi giorni
                        </p>
                    )}
                </div>
            </div>
            {/* )} */}
            {showTokenDetails && (
                <TokenDetails
                    token={showTokenDetails}
                    toggleDialog={() => setShowTokenDetails(false)}
                />
            )}
        </div>
    );
}

const mapStateToProps = ({ general, tutorial, ipfs }) => {
    //TODO: Check not for start of the day but now back 1h or 30min
    let todayTokens = general.tokens.filter((token, i) => {
        return (
            moment(token.begin).isSame(TODAY, "day") &&
            token.status === "booked"
        );
    });

    let tomorrowTokens = general.tokens.filter((token, i) => {
        return (
            moment(token.begin).isSame(TOMORROW, "day") &&
            token.status === "booked"
        );
    });

    let nextDaysTokens = general.tokens.filter((token, i) => {
        return (
            moment(token.begin).isAfter(TOMORROW, "day") &&
            token.status === "booked"
        );
    });

    return {
        user: general.user,
        userStore: general.userStore,
        storeAddress: general.storeAddress,
        tokens: general.tokens,
        nextDaysTokens: nextDaysTokens,
        tomorrowTokens: tomorrowTokens,
        todayTokens: todayTokens,
        tutorial,
        ipfsNode: ipfs.node,
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
