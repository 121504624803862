export const walletConstants = {
  WALLET_CREATE: "[Wallet] Wallet create",
  STORE_CREATE: "[Wallet] Store create",
  LOAD_IPFS_TOKEN: "[Wallet] Load IPFS owned tokens",
  LOAD_BOOKED_TOKENS_REQUEST: "[Wallet] Load booked tokens request",
  LOAD_BOOKED_TOKENS: "[Wallet] Load booked tokens",
  DELETE_TOKEN_REQUEST: "[Wallet] Delete token request",
  DELETE_TOKEN: "[Wallet] Delete token",
  GET_ALL_TOKEN: "[Wallet] Get all token",
  GET_SINGLE_TOKEN: "[Wallet] Get single token",
  LOAD_SINGLE_TOKEN: "[Wallet] Load single token",
  LOGOUT: "[Wallet] Logout",
  WALLET_LOGIN: "[Wallet] Wallet login request",

  LOADING: "[Wallet] Loading",
  LOADING_DONE: "[Wallet] Loading done",
  LOGIN: "[Wallet] Login",
  LOGIN_STORE: "[Wallet] Login Store",
  LOGIN_STORE_REQUEST: "[Wallet] Login Store request",
  LOAD_BOOKED_TOKENS: "[Wallet] Load booked tokens",
  LOAD_TOKEN: "[Wallet] Load token",
  LOAD_TOKENS: "[Wallet] Load tokens",

  USER_LOGOUT: "[Wallet] User logout",
  UPDATE_TOKEN_STATUS: "[Wallet] Update token status",
  CREATE_NODE: "[Wallet] Create node",

  LOAD_SUCCESS: "[Wallet] Load Success",
  LOAD_FAILURE: "[Wallet] Load Failure",
};
