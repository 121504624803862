import React, { useRef, useState, useReducer } from "react";
import { connect } from "react-redux";

import Alert from "@material-ui/lab/Alert";

import {
    ListSubheader,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    Dialog,
    TextField,
    InputAdornment,
    IconButton,
    Button,
    Snackbar,
    Collapse,
} from "@material-ui/core";

import {
    WhatsApp,
    PhotoCamera,
    Business,
    Phone,
    VpnKey,
    FileCopy,
    MailOutline,
    ExpandLess,
    ExpandMore,
    Person,
} from "@material-ui/icons";

import QRCode from "react-qr-code";

import { mailTo } from "../../config/utility";
import {
    EMAIL_KNOBS,
    EMAIL_BCODE,
    EMAIL_SUBJECT,
} from "../../config/constants";

import "./settings.scss";

export const Settings = props => {
    const { mnemonic, address, storeName } = props;

    const mnemonicRef = useRef();

    const [mnemonicDialog, setMnemonicDialog] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [openedMenu, setOpenedMenu] = useState("profile");

    // const initialState = {
    //     mnemonicDialog: false,
    //     showSnackbar: false,
    //     openedMenu: "profile",
    // };
    // const reducer = (state, action) => {
    //     switch (action.type) {
    //         default:
    //             return { ...state, [action.type]: action.payload };
    //     }
    // };

    // const [state, dispatch] = useReducer(reducer, initialState);

    // const { mnemonicDialog, showSnackbar, openedMenu } = state;

    const copyToClipboard = str => {
        console.log(str, " copied");
        mnemonicRef.current.select();
        mnemonicRef.current.setSelectionRange(
            0,
            mnemonicRef.current.value.length,
        );
        document.execCommand("copy");
        setShowSnackbar("Frase mnemonica copiata!");
        // dispatch({
        //     value: "showSnackbar",
        //     payload: "Frase mnemonica copiata!",
        // });
    };

    const printQrCode = () => {
        const printableElements = document.getElementById("qrcode").innerHTML;
        console.log(document.getElementById("qrcode"));
        const orderHTML =
            "<html><head><title></title></head><body>" +
            printableElements +
            "</body></html>";

        //Create new page to prevent miss click in current page
        let newWindow = window.open("", "STAMPA");
        newWindow.document.body.innerHTML = orderHTML;
        newWindow.print();
    };

    const openMenu = value => {
        value === openedMenu ? setOpenedMenu(false) : setOpenedMenu(value);
        // value === openedMenu
        //     ? dispatch({ type: "openedMenu", payload: false })
        //     : dispatch({ type: "openedMenu", payload: value });
    };

    return (
        <div className="impostazioni">
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader
                        component="div"
                        id="nested-list-subheader"
                        // button
                        onClick={() => openMenu("profile")}
                    >
                        <ListItemText primary="Dati Attività" />

                        {openedMenu ? <ExpandLess /> : <ExpandMore />}
                    </ListSubheader>
                }
            >
                <Collapse in={true}>
                    <ListItem disabled>
                        <ListItemIcon>
                            <PhotoCamera />
                        </ListItemIcon>
                        {/* <ListItemText primary="Cambia foto del negozio" /> */}
                        <TextField
                            // className="mnemonic-holder"
                            // type="text"
                            // variant="outlined"
                            // id="save-mnemonic"
                            // color="secondary"
                            defaultValue={storeName}
                            onChange={() => {}}
                        ></TextField>
                    </ListItem>
                    <ListItem disabled>
                        <ListItemIcon>
                            <Business />
                        </ListItemIcon>
                        <ListItemText primary="Cambia indirizzo attività" />
                    </ListItem>
                    <ListItem disabled>
                        <ListItemIcon>
                            <Phone />
                        </ListItemIcon>
                        <ListItemText primary="Cambia numero di telefono" />
                    </ListItem>
                </Collapse>
            </List>

            <Divider />
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                onClick={() => openMenu("mnemonic")}
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        <ListItemText primary="Sicurezza e Privacy" />
                        {openedMenu ? <ExpandLess /> : <ExpandMore />}
                    </ListSubheader>
                }
            >
                <Collapse in={openedMenu === "mnemonic"}>
                    <ListItem
                        onClick={() =>
                            // dispatch({
                            //     value: "mnemonicDialog",
                            //     payload: true,
                            // })
                            setMnemonicDialog(true)
                        }
                    >
                        <ListItemIcon>
                            <VpnKey />
                        </ListItemIcon>
                        <ListItemText primary="Visualizza frase mnemonica" />
                    </ListItem>
                </Collapse>
            </List>
            <Divider />
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                onClick={() => openMenu("share")}
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        <ListItemText primary="Condivisione" />
                        {openedMenu ? <ExpandLess /> : <ExpandMore />}
                    </ListSubheader>
                }
            >
                <Collapse in={openedMenu === "share"}>
                    <ListItem
                        onClick={() => {
                            const el = document.createElement("textarea");
                            el.value = `https://prenotazioni.knobs.it/store/${address}`;
                            document.body.appendChild(el);
                            el.select();
                            document.execCommand("copy");
                            document.body.removeChild(el);
                            // setShowSnackbar("Link copiato!");
                            // dispatch({
                            //     value: "showSnackbar",
                            //     payload: "Link copiato!",
                            // });
                            setShowSnackbar("Link copiato!");
                        }}
                    >
                        <ListItemIcon>
                            <FileCopy />
                        </ListItemIcon>
                        <ListItemText primary="Copia link da condividere" />
                    </ListItem>

                    <ListItem
                        onClick={() => {
                            window.open(
                                `https://api.whatsapp.com/send?text=https://prenotazioni.knobs.it/store/${address}`,
                                "_blank",
                            );
                            // win.focus();
                        }}
                    >
                        <ListItemIcon>
                            <WhatsApp />
                        </ListItemIcon>
                        <ListItemText primary="Condividi con Whatsapp" />
                    </ListItem>

                    <ListItem onClick={() => printQrCode()}>
                        <ListItemIcon>
                            <QRCode
                                value={`https://prenotazioni.knobs.it/store/${address}`}
                                size={20}
                            />
                        </ListItemIcon>
                        <ListItemText primary="Stampa QRCode" />
                    </ListItem>

                    <ListItem>
                        <ListItemIcon>
                            <MailOutline />
                        </ListItemIcon>
                        <a
                            style={{ textDecoration: "none", color: "#000" }}
                            href={mailTo(
                                `${EMAIL_BCODE}; ${EMAIL_KNOBS}`,
                                `${EMAIL_SUBJECT} ${storeName}`,
                                address,
                            )}
                        >
                            Invia email di verifica
                        </a>
                    </ListItem>

                    {/* HiddenQRCode */}
                    <div style={{ visibility: "hidden" }} id="qrcode">
                        <QRCode
                            value={`https://prenotazioni.knobs.it/store/${address}`}
                            size={250}
                        />
                    </div>
                </Collapse>
            </List>

            <Dialog
                className="mnemonic-dialog dialog-wrapper"
                aria-labelledby="customized-dialog-title"
                open={mnemonicDialog}
            >
                <div className="dialog-content">
                    {/* <div className="headline">
            <Typography variant="h5">Dettagli Token</Typography>
          </div> */}
                    <TextField
                        className="mnemonic-holder"
                        type="text"
                        variant="outlined"
                        id="save-mnemonic"
                        color="secondary"
                        rows={4}
                        multiline
                        defaultValue={mnemonic}
                        readOnly
                        InputProps={{
                            readOnly: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() =>
                                            copyToClipboard(mnemonic)
                                        }
                                        edge="end"
                                    >
                                        <FileCopy />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    ></TextField>

                    <input
                        value={mnemonic}
                        ref={mnemonicRef}
                        readOnly
                        style={{ position: "absolute", top: "-10000000px" }}
                    />
                </div>
                <div className="buttons-wrapper">
                    <Button
                        onClick={() =>
                            // dispatch({
                            //     value: "mnemonicDialog",
                            //     payload: !mnemonicDialog,
                            // })
                            setMnemonicDialog(!mnemonicDialog)
                        }
                        variant="contained"
                        color="primary"
                        disableElevation
                    >
                        Ok
                    </Button>
                </div>
            </Dialog>
            <Snackbar
                open={!!showSnackbar}
                autoHideDuration={2000}
                onClose={() => {
                    // dispatch({ value: "showSnackbar", payload: false });
                    setShowSnackbar(false);
                }}
                style={{ position: "fixed", bottom: "70px" }}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={() => {
                        // dispatch({ value: "showSnackbar", payload: false });
                        setShowSnackbar(false);
                    }}
                    severity="success"
                >
                    {showSnackbar}
                </Alert>
            </Snackbar>
        </div>
    );
};

const mapStateToProps = ({ general }) => {
    return {
        mnemonic: general.user.mnemonic.phrase,
        address: general.storeAddress,
        storeName: general.storeName,
    };
};

export default connect(mapStateToProps)(Settings);
