import React, { useRef } from "react";

import * as Actions from "../../redux/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import SettingsIcon from "@material-ui/icons/Settings";
import ExitToApp from "@material-ui/icons/ExitToApp";
import CalendarToday from "@material-ui/icons/CalendarToday";
import AddBox from "@material-ui/icons/AddBox";
import Home from "@material-ui/icons/Home";

import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";

import "./drawer.scss";

function Drawer(
    {
        isDrawerOpen,
        toggleDrawer,
        storeName,
        storeAddress,
        trusted,
        logout,
        finishTutorial,
    },
    props,
) {
    const refInput = useRef();
    const [showSnackbar, setShowSnackbar] = React.useState(false);

    const truncStringPortion = (
        str,
        firstCharCount = str.length,
        endCharCount = 0,
        dotCount = 3,
    ) => {
        let convertedStr = "";
        convertedStr += str.substring(0, firstCharCount);
        convertedStr += ".".repeat(dotCount);
        convertedStr += str.substring(str.length - endCharCount, str.length);
        return convertedStr;
    };

    const copyToClipboard = str => {
        console.log(str, " copied");
        refInput.current.select();
        refInput.current.setSelectionRange(0, refInput.current.value.length);
        document.execCommand("copy");
        setShowSnackbar(true);
    };

    const loggingOut = () => {
        logout();
    };

    return (
        <SwipeableDrawer
            id="drawer"
            open={isDrawerOpen}
            onOpen={() => toggleDrawer(true)}
            onClose={() => toggleDrawer(false)}
        >
            <div className="section section-top">
                <span className="step-1 wrapper">
                    <Avatar className="avatar" />
                    <div className="container">
                        <div className="store-data">
                            <Typography
                                className="title"
                                variant="h1"
                                component="h1"
                            >
                                {storeName}
                            </Typography>
                            <p onClick={e => copyToClipboard(storeAddress)}>
                                {truncStringPortion(storeAddress, 10, 10)}
                            </p>
                            <input
                                value={storeAddress}
                                ref={refInput}
                                readOnly
                                style={{
                                    position: "absolute",
                                    top: "-10000000px",
                                }}
                            />
                        </div>
                        {trusted && (
                            <VerifiedUserIcon className={"verified-check"} />
                        )}
                    </div>
                </span>
            </div>
            <div className="section section-bottom">
                <List className="list">
                    <Link className="link" to="/">
                        <ListItem button onClick={() => toggleDrawer(false)}>
                            <ListItemIcon>
                                <Home className="icon" />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography
                                    className="text"
                                    variant="h2"
                                    component="h2"
                                >
                                    Dashboard
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    </Link>
                    <Link className="link" to="/gestione-token">
                        <ListItem
                            className="step-1"
                            button
                            onClick={() => {
                                toggleDrawer(false);
                            }}
                        >
                            <ListItemIcon>
                                <AddBox className="icon" />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography
                                    className="text"
                                    variant="h2"
                                    component="h2"
                                >
                                    Gestione Token
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    </Link>
                    <Link className="link" to="/calendario">
                        <ListItem
                            button
                            onClick={() => {
                                finishTutorial();
                                toggleDrawer(false);
                            }}
                        >
                            <ListItemIcon>
                                <CalendarToday className="icon" />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography
                                    className="text"
                                    variant="h2"
                                    component="h2"
                                >
                                    Vista Calendario
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    </Link>
                    <Link className="link" to="/settings">
                        <ListItem button onClick={() => toggleDrawer(false)}>
                            <ListItemIcon>
                                <SettingsIcon className="icon" />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography
                                    className="text"
                                    variant="h2"
                                    component="h2"
                                >
                                    Impostazioni
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    </Link>
                    <ListItem button onClick={() => loggingOut()}>
                        <ListItemIcon>
                            <ExitToApp className="icon" />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography
                                className="text"
                                variant="h2"
                                component="h2"
                            >
                                Logout
                            </Typography>
                        </ListItemText>
                    </ListItem>
                </List>
            </div>

            <Snackbar
                open={showSnackbar}
                autoHideDuration={2000}
                onClose={() => {
                    setShowSnackbar(false);
                }}
                style={{ position: "fixed", bottom: "70px" }}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={() => {
                        setShowSnackbar(false);
                    }}
                    severity="success"
                >
                    Indirizzo del negozio copiato!
                </Alert>
            </Snackbar>
        </SwipeableDrawer>
    );
}

const mapStateToProps = ({ general, tutorial }) => {
    return {
        loading: general.loading,
        storeName: general.storeName,
        storeAddress: general.storeAddress,
        tutorial,
        trusted: general.trusted,
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Drawer);
