import React from "react";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

export default function snackbar({ label, status, open, onClose }) {
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={() => onClose()}>
      <MuiAlert elevation={6} variant="filled" onClose={() => onClose()} severity={status}>
        {label}
      </MuiAlert>
    </Snackbar>
  );
}
