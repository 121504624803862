import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
    Card,
    CardContent,
    Typography,
    Button,
    Dialog,
    IconButton,
} from "@material-ui/core";
import Settings from "@material-ui/icons/Settings";

import moment from "moment";
import localization from "moment/locale/it";

import { truncStringPortion } from "../../config/utility";

import "./style.scss";

const TODAY = moment();

function DialogHeadline({ title, setoptionsDialog }) {
    return (
        <div className="headline">
            <Typography variant="h5">{title}</Typography>
            {setoptionsDialog && (
                <IconButton
                    color="secondary"
                    className="options"
                    onClick={() => setoptionsDialog(true)}
                    edge="end"
                >
                    <Settings />
                </IconButton>
            )}
        </div>
    );
}

function Time({ begin, end, small }) {
    return (
        <div className="time">
            {!small && <Typography variant="h6">Ora</Typography>}
            <Typography
                variant={small ? null : "h6"}
                component={small ? "p" : null}
            >
                {moment(begin).format("HH:mm")}-{moment(end).format("HH:mm")}
            </Typography>
        </div>
    );
}

function Date({ fullDate, small }) {
    return (
        <div className="date">
            {!small && <Typography variant="h6">Data</Typography>}
            <Typography
                variant={small ? null : "h6"}
                component={small ? "p" : null}
            >
                {moment(fullDate).format("DD/MM/YYYY")}
            </Typography>
        </div>
    );
}

function TokenId(props) {
    const { children, id, small } = props;
    return (
        <div className="tokenid">
            <Typography
                variant={small ? null : "body2"}
                component={small ? "p" : null}
            >
                {children}{" "}
                {truncStringPortion(id, small ? 11 : 15, small ? 5 : 15)}
            </Typography>
        </div>
    );
}

function TokenStats({ freeTokens, bookedTokens, hideZero }) {
    return (
        <div className="stats-wrapper">
            {hideZero ? (
                freeTokens === 0 ? (
                    ""
                ) : (
                    <span className="free" component="p">
                        {freeTokens}
                    </span>
                )
            ) : (
                <span className="free" component="p">
                    {freeTokens}
                </span>
            )}
            {hideZero ? (
                bookedTokens === 0 ? (
                    ""
                ) : (
                    <span className="booked" component="p">
                        {bookedTokens}
                    </span>
                )
            ) : (
                <span className="booked" component="p">
                    {bookedTokens}
                </span>
            )}
        </div>
    );
}

export function TokenCard({ token, status, tokenId, today, handleClick }) {
    moment.locale("it", localization);
    const begin = moment(token.begin);
    const end = moment(token.end);
    const remaining = begin.from(TODAY);

    return (
        <div className={"token-card-wrapper " + (status ? status : "")}>
            <Card
                onClick={() => handleClick()}
                raised={false}
                className="token-card-container"
            >
                <CardContent className="token-card-content">
                    {!tokenId && (
                        <div className="date-wrapper">
                            <Time begin={begin} end={end} small />
                            <Date fullDate={begin} small />
                        </div>
                    )}
                    <Typography className="remaining" component="h2">
                        {today && remaining}
                    </Typography>
                    {tokenId && (
                        <TokenId id={tokenId} small>
                            ID:
                        </TokenId>
                    )}
                </CardContent>
            </Card>
        </div>
    );
}

export function TokenDetails({ token, toggleDialog, handleTokenBurn }) {
    const [optionsDialog, setoptionsDialog] = useState(false);

    return (
        <div className="token-dialog-wrapper">
            <Dialog
                className="token-details dialog-wrapper"
                aria-labelledby="customized-dialog-title"
                open={optionsDialog ? false : true}
            >
                <div className="dialog-content">
                    <DialogHeadline
                        title="Dettagli Token"
                        setoptionsDialog={setoptionsDialog}
                    />
                    <TokenId id={token.id} />
                    <Date fullDate={token.begin} />
                    <Time begin={token.begin} end={token.end} />
                    <div className="status">
                        <Typography variant="h6">Stato</Typography>
                        <Typography variant="h6" className={token.status}>
                            {token.status === "booked"
                                ? "PRENOTATO"
                                : token.status === "free"
                                ? "DISPONIBILE"
                                : "ELIMINATO"}
                        </Typography>
                    </div>
                </div>
                <div className="buttons-wrapper">
                    <Button
                        onClick={() => toggleDialog()}
                        variant="contained"
                        color="primary"
                        disableElevation
                    >
                        Ok
                    </Button>
                </div>
            </Dialog>
            {token.status === "booked" ? (
                <Dialog
                    className="token-details dialog-wrapper "
                    aria-labelledby="customized-dialog-title"
                    open={optionsDialog ? true : false}
                >
                    <div className="buttons-wrapper">
                        <Button
                            onClick={() => toggleDialog()}
                            variant="contained"
                            color="secondary"
                            disableElevation
                            disabled
                        >
                            Annulla appuntamento
                        </Button>
                        <Button
                            onClick={() => toggleDialog()}
                            variant="contained"
                            color="secondary"
                            disableElevation
                            disabled
                        >
                            Segnala no-show
                        </Button>
                        <Button
                            onClick={() => setoptionsDialog(false)}
                            variant="contained"
                            color="primary"
                            disableElevation
                        >
                            Indietro
                        </Button>
                    </div>
                </Dialog>
            ) : (
                token.status === "free" && (
                    <Dialog
                        className="token-details dialog-wrapper "
                        aria-labelledby="customized-dialog-title"
                        open={optionsDialog ? true : false}
                    >
                        <div className="buttons-wrapper">
                            <Button
                                onClick={() => handleTokenBurn(token.id)}
                                variant="contained"
                                color="secondary"
                                disableElevation
                            >
                                Elimina token
                            </Button>
                            <Button
                                onClick={() => setoptionsDialog(false)}
                                variant="contained"
                                color="primary"
                                disableElevation
                            >
                                Indietro
                            </Button>
                        </div>
                    </Dialog>
                )
            )}
        </div>
    );
}

export function MultipleTokensDetails({
    tokens,
    toggleDialog,
    handleTokenClick,
    handleMultipleTokensBurn,
}) {
    const [optionsDialog, setoptionsDialog] = useState(false);

    let freeTokens = tokens.filter(t => t.status === "free");
    let bookedTokens = tokens.filter(t => t.status === "booked");

    return (
        <div className="token-dialog-wrapper">
            <Dialog
                className="token-details dialog-wrapper multiple"
                aria-labelledby="customized-dialog-title"
                open={optionsDialog ? false : true}
            >
                <div className="dialog-content">
                    <DialogHeadline
                        title="Dettagli Tokens"
                        setoptionsDialog={setoptionsDialog}
                    />
                    <div className="date-wrapper">
                        <Time
                            begin={tokens[0].begin}
                            end={tokens[0].end}
                            small
                        />
                        <Date fullDate={tokens[0].begin} small />
                    </div>
                    <TokenStats
                        freeTokens={freeTokens.length}
                        bookedTokens={bookedTokens.length}
                    />
                    <div className="tokens-wrapper">
                        {tokens.map((token, i) => (
                            <TokenCard
                                handleClick={() => handleTokenClick([token])}
                                key={i}
                                token={token}
                                status={token.status}
                                tokenId={token.id}
                            />
                        ))}
                    </div>
                </div>
                <div className="buttons-wrapper">
                    <Button
                        onClick={() => toggleDialog()}
                        variant="contained"
                        color="primary"
                        disableElevation
                    >
                        Ok
                    </Button>
                </div>
            </Dialog>
            <Dialog
                className="token-details dialog-wrapper"
                aria-labelledby="customized-dialog-title"
                open={optionsDialog ? true : false}
            >
                <div className="buttons-wrapper">
                    <Button
                        onClick={() => toggleDialog()}
                        variant="contained"
                        color="secondary"
                        disableElevation
                        disabled
                    >
                        Annulla appuntamenti
                    </Button>
                    <Button
                        onClick={() =>
                            handleMultipleTokensBurn(freeTokens.map(t => t.id))
                        }
                        variant="contained"
                        color="secondary"
                        disableElevation
                        disabled={!freeTokens.length}
                    >
                        Elimina token non prenotati
                    </Button>
                    <Button
                        onClick={() => setoptionsDialog(false)}
                        variant="contained"
                        color="primary"
                        disableElevation
                    >
                        Indietro
                    </Button>
                </div>
            </Dialog>
        </div>
    );
}

//Used in Calendar page to show tokens, their time range and the number of available and non available tokens if there's multiple
export function TimeButton({
    tokens,
    tokenId,
    begin,
    end,
    status,
    handleClick,
}) {
    const showMultipleTokens = useSelector(
        state => state.general.userOptions.showMultipleTokens,
    );

    let freeTokens = tokens.filter(t => t.status === "free");
    let bookedTokens = tokens.filter(t => t.status === "booked");

    return (
        <div
            onClick={() => handleClick()}
            key={tokenId}
            className={
                `time-button available ` +
                status +
                (showMultipleTokens ? " show" : "")
            }
        >
            <TokenStats
                hideZero
                freeTokens={freeTokens.length}
                bookedTokens={bookedTokens.length}
            />

            <div>{moment(begin).format("HH:mm")}</div>
            <div>{moment(end).format("HH:mm")}</div>
        </div>
    );
}

// import React, { useState } from "react";

// import { connect } from "react-redux";

// import { Card, CardContent, Typography, Button, Dialog, IconButton } from "@material-ui/core";
// import Settings from "@material-ui/icons/Settings";

// import moment from "moment";
// import localization from "moment/locale/it";

// const REFERENCE = moment();

// export function TokenCard(props) {
//   moment.locale("it", localization);
//   const begin = moment(props.token.begin);
//   const end = moment(props.token.end);
//   const remaining = begin.from(REFERENCE);

//   return (
//     <div className="token-card-wrapper">
//       <Card onClick={() => props.handleClick()} raised={false} className="token-card-container">
//         <CardContent className="token-card-content">
//           {/* <Typography variant="h5" gutterBottom>
//             {props.token.id}
//           </Typography> */}
//           <div className="date-wrapper">
//             <Typography className="time" component="p">
//               {begin.format("HH:mm")} {end.format("HH:mm")}
//             </Typography>
//             <Typography className="date" component="p">
//               {begin.format("DD/MM/YYYY")}
//             </Typography>
//           </div>
//           <Typography className="remaining" component="h2">
//             {props.today && remaining}
//           </Typography>
//         </CardContent>
//       </Card>
//     </div>
//   );
// }

// export function TokenDetails(props) {
//   const [optionsDialog, setoptionsDialog] = useState(false);

//   const truncStringPortion = (str, firstCharCount = str.length, endCharCount = 0, dotCount = 3) => {
//     let convertedStr = "";
//     convertedStr += str.substring(0, firstCharCount);
//     convertedStr += ".".repeat(dotCount);
//     convertedStr += str.substring(str.length - endCharCount, str.length);
//     return convertedStr;
//   };

//   return (
//     <div className="token-dialog-wrapper">
//       <Dialog
//         className="token-details dialog-wrapper"
//         aria-labelledby="customized-dialog-title"
//         open={optionsDialog ? false : true}
//       >
//         <div className="dialog-content">
//           <div className="headline">
//             <Typography variant="h5">Dettagli Token</Typography>
//             <IconButton
//               color="secondary"
//               className="options"
//               onClick={() => setoptionsDialog(true)}
//               edge="end"
//             >
//               <Settings />
//             </IconButton>
//           </div>
//           <Typography variant="body2">{truncStringPortion(props.token.id, 15, 15)}</Typography>
//           <Typography className="address" variant="body2">
//             **Indirizzo**
//           </Typography>
//           <div className="date">
//             <Typography variant="h6">Data</Typography>
//             <Typography variant="h6">{moment(props.token.begin).format("DD/MM/YYYY")}</Typography>
//           </div>
//           <div className="time">
//             <Typography variant="h6">Ora</Typography>
//             <Typography variant="h6">
//               {moment(props.token.begin).format("HH:mm")}-{moment(props.token.end).format("HH:mm")}
//             </Typography>
//           </div>
//           <div className="status">
//             <Typography variant="h6">Stato</Typography>
//             <Typography variant="h6" className={props.token.status}>
//               {props.token.status === "booked"
//                 ? "PRENOTATO"
//                 : props.token.status === "free"
//                 ? "DISPONIBILE"
//                 : "ELIMINATO"}
//             </Typography>
//           </div>
//         </div>
//         <div className="buttons-wrapper">
//           <Button onClick={() => props.toggleDialog()} variant="contained" color="primary" disableElevation>
//             Ok
//           </Button>
//         </div>
//       </Dialog>
//       {props.token.status === "booked" ? (
//         <Dialog
//           className="token-details dialog-wrapper"
//           aria-labelledby="customized-dialog-title"
//           open={optionsDialog ? true : false}
//         >
//           <div className="buttons-wrapper">
//             <Button
//               onClick={() => props.toggleDialog()}
//               variant="contained"
//               color="secondary"
//               disableElevation
//             >
//               Annulla appuntamento
//             </Button>
//             <Button
//               onClick={() => props.toggleDialog()}
//               variant="contained"
//               color="secondary"
//               disableElevation
//             >
//               Segnala no-show
//             </Button>
//             <Button
//               onClick={() => setoptionsDialog(false)}
//               variant="contained"
//               color="primary"
//               disableElevation
//             >
//               Indietro
//             </Button>
//           </div>
//         </Dialog>
//       ) : props.token.status === "free" ? (
//         <Dialog
//           className="token-details dialog-wrapper"
//           aria-labelledby="customized-dialog-title"
//           open={optionsDialog ? true : false}
//         >
//           <div className="buttons-wrapper">
//             <Button
//               onClick={() => props.handleTokenBurn(props.token.id)}
//               variant="contained"
//               color="secondary"
//               disableElevation
//             >
//               Elimina token
//             </Button>
//             <Button
//               onClick={() => setoptionsDialog(false)}
//               variant="contained"
//               color="primary"
//               disableElevation
//             >
//               Indietro
//             </Button>
//           </div>
//         </Dialog>
//       ) : (
//         <Dialog
//           className="token-details dialog-wrapper"
//           aria-labelledby="customized-dialog-title"
//           open={optionsDialog ? true : false}
//         >
//           <div className="buttons-wrapper">
//             <Button
//               onClick={() => setoptionsDialog(false)}
//               variant="contained"
//               color="secondary"
//               disableElevation
//             >
//               Ripristina token
//             </Button>
//             <Button
//               onClick={() => setoptionsDialog(false)}
//               variant="contained"
//               color="primary"
//               disableElevation
//             >
//               Indietro
//             </Button>
//           </div>
//         </Dialog>
//       )}
//     </div>
//   );
// }

// export function TimeButton(props) {
//   return (
//     <div
//       onClick={() => props.handleClick()}
//       key={props.tokenId}
//       className={`time-button available ` + props.status}
//     >
//       <div>{moment(props.begin).format("HH:mm")}</div>
//       <div>{moment(props.end).format("HH:mm")}</div>
//     </div>
//   );
// }
