import { walletConstants } from "../constants/wallet.constants";
import produce from "immer";

const initialState = {
  user: {},
  userStore: {},
  userOptions: {
    showMultipleTokens: true,
  },
  storeName: "",
  storeAddress: "",
  loading: false,
  tokens: [],
  lastIPFSHash: "",
  trusted: false,
};

export const general = produce((draft, action) => {
  switch (action.type) {
    case walletConstants.WALLET_CREATE:
    case walletConstants.STORE_CREATE:
    case walletConstants.WALLET_LOGIN:
    case walletConstants.LOAD_IPFS_TOKEN:
    case walletConstants.LOAD_BOOKED_TOKENS_REQUEST:
    case walletConstants.LOADING: {
      draft.loading = true;
      return draft;
    }
    case walletConstants.LOAD_SUCCESS: {
      draft.loading = false;
      break;
    }

    case walletConstants.LOAD_FAILURE: {
      draft.loading = false;
      draft.error = true;
    }
    case walletConstants.LOADING_DONE: {
      draft.loading = false;
      return draft;
    }
    case walletConstants.USER_LOGOUT: {
      draft = initialState;
      return draft;
    }
    case walletConstants.LOGIN: {
      draft.user = action.payload;
      return draft;
    }
    case walletConstants.LOGIN_STORE: {
      draft.userStore = action.payload.store;
      draft.storeName = action.payload.name;
      draft.storeAddress = action.payload.address;
      draft.trusted = action.payload.trusted;
      return draft;
    }
    case walletConstants.LOAD_TOKEN: {
      const tokenIndex = draft.tokens.findIndex(
        (t) => t.id === action.payload.token.id
      );

      tokenIndex === -1
        ? draft.tokens.push(action.payload.token)
        : (draft.tokens[tokenIndex] = action.payload.token);

      return draft;
    }

    case walletConstants.LOAD_TOKENS:
      draft.tokens = action.payload;
      return draft;

    case walletConstants.LOAD_SINGLE_TOKEN: {
      const tokenIndex = draft.tokens.findIndex(
        (t) => t.id === action.payload.token.id
      );
      tokenIndex === -1
        ? draft.tokens.push(action.payload.token)
        : (draft.tokens[tokenIndex] = action.payload.token);

      draft.loading = false;
      return draft;
    }
    case walletConstants.DELETE_TOKEN: {
      const tokenIndex = draft.tokens.findIndex((t) => t.id === action.payload);
      draft.tokens.splice(tokenIndex, 1);

      return draft;
    }
    case walletConstants.UPDATE_TOKEN_STATUS: {
      const tokenIndex = draft.tokens.findIndex((t) => t.id === action.payload);
      draft.tokens[tokenIndex].status = "booked";
      return draft;
    }

    // case walletConstants.USEROPTIONS_SET_SHOW_MULTIPLE_TOKENS: {
    //   draft.userOptions.showMultipleTokens = !draft.userOptions.showMultipleTokens;
    //   return draft;
    // }
    // case walletConstants.SET_LAST_IPFS_HASH: {
    //   draft.lastIPFSHash = action.payload;
    //   return draft;
    // }
    // no default
  }
}, initialState);
