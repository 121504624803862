import React, { Component } from "react";

import { BrowserRouter as Router, Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { walletLogin } from "../redux/actions/wallet.actions";

import { ethers } from "ethers";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Spinner from "../components/spinner/spinner";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mnemonic: "",
      error: "",
      screen: "first",
    };
  }

  validation = () => {
    if (!this.state.mnemonic) {
      this.setState({ error: "Inserisci la frase prima di procedere" });

      return false;
    }

    this.setState({ error: "" });
    return true;
  };

  login = () => {
    if (this.validation()) {
      try {
        ethers.Wallet.fromMnemonic(this.state.mnemonic);
        this.props.walletLogin({ mnemonic: this.state.mnemonic });
      } catch (error) {
        this.setState({ error: "La frase inserita non è corretta, riprova." });

        return false;
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loading !== this.props.loading) {
      if (this.props.userStore) {
        this.props.history.push("/");
      }
    }
  }

  render() {
    return (
      <>
        <Spinner show={this.props.loading} additionalText={true} />
        {this.state.screen === "first" ? (
          <div className="login">
            <h1 className="title">Prenotazioni</h1>
            <h3>Registrati o crea il tuo account</h3>

            <span className="error">{this.state.error}</span>
            <div className="buttons-wrapper">
              <Button
                variant="contained"
                color="secondary"
                disableElevation
                onClick={() => this.setState({ screen: "login" })}
              >
                Connetti account
              </Button>
              <Link to={"/creazione-store"}>
                <Button variant="outlined" color="primary" disableElevation>
                  Crea nuovo account
                </Button>
              </Link>
            </div>
          </div>
        ) : (
          <div className="login">
            <h1 className="title">Prenotazioni</h1>
            <h3>
              Inserisci la tua frase mnemonica per accedere oppure crea un nuovo
              account
            </h3>
            <TextField
              className="input"
              type="text"
              variant="outlined"
              id="mnemonic"
              rows={4}
              color="secondary"
              multiline
              // inputRef={this.mnemonicInput}
              placeholder="word cosmetics phone keys world mountain true single"
              onChange={(e) => this.setState({ mnemonic: e.target.value })}
              // InputProps={{
              //   endAdornment: (
              //     <InputAdornment position="end">
              //       <IconButton onClick={() => this.paste()} edge="end">
              //         <SaveAltIcon />
              //       </IconButton>
              //     </InputAdornment>
              //   ),
              // }}w
            ></TextField>
            <span className="error">{this.state.error}</span>
            <div className="buttons-wrapper">
              <LoginButton login={this.login} />
              <Button
                variant="outlined"
                color="primary"
                disableElevation
                onClick={() => this.setState({ screen: "first", error: "" })}
              >
                Indietro
              </Button>
            </div>
            {/* <Backdrop style={{ zIndex: "1000" }} open={this.props.loading}>
          <CircularProgress color="inherit" />
        </Backdrop> */}
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ general }) => {
  return {
    user: general.user,
    userStore: general.userStore,
    loading: general.loading,
  };
};

export default connect(mapStateToProps, { walletLogin })(Login);

function LoginButton(props) {
  const history = useHistory();

  return (
    <Button
      variant="contained"
      color="secondary"
      disableElevation
      onClick={props.login}
    >
      Accedi
    </Button>
  );
}
