import React, { Component } from "react";
import { connect } from "react-redux";

import "./spinner.scss";
import spinner from "../../assets/spinner.png";

import { ADDITIONAL_TEXT } from "../../config/constants";
import { getRandom } from "../../config/utility";

function Spinner(props) {
  return (
    <div>
      {props.show && (
        <div className="div-fade">
          <div className="fade" />
          <div className="spinner-wrap">
            <img src={spinner} className="spinner" />
            {!props.noWords && <p className="text">Caricamento ...</p>}
            {props.additionalText && (
              <p className="text">{ADDITIONAL_TEXT[getRandom(0, ADDITIONAL_TEXT.length - 1)]}</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Spinner;
