import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Link, useRouteMatch } from "react-router-dom";
import moment from "moment";

import * as Actions from "../redux/actions";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import { store } from "../redux/store/index";

import { deleteToken } from "../redux/actions/wallet.actions";

import { address_burn } from "../config/contract";
import { provider } from "../enhancers/createIpfsEnhancer";
import {
    TextField,
    Button,
    Typography,
    Dialog,
    Snackbar,
} from "@material-ui/core";
import Spinner from "../components/spinner/spinner";
import Alert from "@material-ui/lab/Alert";

function GestioneToken(props) {
    let { url } = useRouteMatch();
    const { tutorial, startTutorial } = props;

    const [showDeleteDialog, setshowDeleteDialog] = useState(false);
    const [showSnackbar, setshowSnackbar] = useState(false);

    const [loading, setLoading] = useState(false);

    const [dates, setValues] = useState({
        begin: moment(new Date()).format("YYYY-MM-DD"),
        end: moment(new Date()).add("days", 1).format("YYYY-MM-DD"),
    });

    const handleChange = prop => event => {
        if (
            prop === "end" &&
            moment(event.target.value).isBefore(moment(dates.begin))
        ) {
            setValues({ ...dates, [prop]: dates.begin });
        } else if (
            prop === "begin" &&
            moment(dates.end).isBefore(moment(event.target.value))
        ) {
            setValues({ begin: event.target.value, end: event.target.value });
        } else {
            setValues({ ...dates, [prop]: event.target.value });
        }
    };

    const initTokenBurn = async () => {
        setLoading(true);
        const tokens = filterTokens(dates.begin, dates.end);

        for (let i = 0; i < tokens.length; i++) {
            await props.getSingleToken(
                tokens[i].id,
                props.userStore,
                props.user.address,
            );
            console.log("init:", tokens[i]);
        }
        setLoading(false);
        setshowDeleteDialog(true);
    };

    useEffect(() => {
        if (tutorial.firstTime) startTutorial();
    }, []);

    return (
        <div className="gestione-token">
            <div className="step-1 form-wrapper">
                <TextField
                    className="date"
                    id="date"
                    label="Inizio"
                    variant="outlined"
                    color="secondary"
                    type="date"
                    value={dates.begin}
                    onChange={handleChange("begin")}
                    format="dd-MM-yyyy"
                    InputLabelProps={{
                        shrink: true,
                        min: moment().format("DD-MM-YYYY"),
                    }}
                />
                <TextField
                    className="date"
                    id="date"
                    label="Fine"
                    type="date"
                    variant="outlined"
                    color="secondary"
                    value={dates.end}
                    format="dd-MM-yyyy"
                    onChange={handleChange("end")}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </div>
            <div className="buttons-wrapper">
                <Link to={`${url}/creazione-token/${dates.begin}/${dates.end}`}>
                    <Button
                        onClick={props.startTutorial}
                        variant="contained"
                        color="secondary"
                        disableElevation
                    >
                        Genera nuovi Token
                    </Button>
                </Link>
                <Button
                    onClick={() => initTokenBurn()}
                    variant="contained"
                    color="secondary"
                    disableElevation
                >
                    Elimina Token generati
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    disableElevation
                    disabled
                >
                    Annulla Token già prenotati
                </Button>
                {/* <Link to={`${url}/`}>
          <Button variant="contained" color="primary" disableElevation>
            Elimina Token generati
          </Button>
        </Link>
        <Link to={`${url}/`}>
          <Button variant="contained" color="primary" disableElevation>
            Annulla Token già prenotati
          </Button>
        </Link> */}
            </div>
            {showDeleteDialog && (
                <EliminaToken
                    timeRange={dates}
                    toggleSnackbar={() => setshowSnackbar(true)}
                    toggleDialog={() => setshowDeleteDialog(false)}
                />
            )}
            <Snackbar
                open={showSnackbar}
                autoHideDuration={2000}
                onClose={() => {
                    setshowSnackbar(false);
                }}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={() => {
                        setshowSnackbar(false);
                    }}
                    severity="success"
                >
                    Tokens eliminati con successo!
                </Alert>
            </Snackbar>
            <Spinner show={loading} />
        </div>
    );
}

const mapStateToProps = ({ general, tutorial }) => ({
    user: general.user,
    userStore: general.userStore,
    storeAddress: general.storeAddress,
    tutorial,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(GestioneToken);

function EliminaToken(props) {
    const dispatch = useDispatch();

    const burnTokens = async tokens => {
        let state = store.getState().general;

        let currNonce = await state.user
            .connect(provider)
            .getTransactionCount();

        tokens.map((token, index) => {
            state.userStore
                .transferFrom(state.user.address, address_burn, token.id)
                // .burn(token.id, {
                //   nonce: currNonce + index,
                //   gasPrice: ethers.utils.parseUnits("6.0", "gwei"),
                //   gasLimit: 6000000,
                // })
                .then(result => {
                    return result.wait();
                })
                .then(() => {
                    console.log("Token: ", token.id, " has been burned!");
                    dispatch(deleteToken(token.id));
                })
                .catch(error => {
                    console.log(error);
                });
        });

        props.toggleSnackbar();
    };

    return (
        <div>
            <Dialog
                className="eliminazione-token dialog-wrapper"
                aria-labelledby="customized-dialog-title"
                open={true}
            >
                <div className="dialog-content">
                    <Typography variant="h5" className="title">
                        Eliminazione Token non acquistati
                    </Typography>
                    <Typography>
                        Stai per eliminare "
                        {
                            filterTokens(
                                props.timeRange.begin,
                                props.timeRange.end,
                            ).length
                        }
                        " token prenotazioni che vanno dal "
                        {moment(props.timeRange.begin).format("DD/MM/YYYY")}" al
                        "{moment(props.timeRange.end).format("DD/MM/YYYY")}".
                        <br />
                        Sei sicuro di voler procedere?
                    </Typography>
                </div>
                <div className="buttons-wrapper">
                    <Button
                        onClick={() => props.toggleDialog()}
                        variant="contained"
                        disableElevation
                        color="primary"
                    >
                        Indietro
                    </Button>
                    <Button
                        onClick={() => {
                            props.toggleDialog();
                            burnTokens(
                                filterTokens(
                                    props.timeRange.begin,
                                    props.timeRange.end,
                                ),
                            );
                        }}
                        variant="contained"
                        color="secondary"
                        disableElevation
                    >
                        Conferma
                    </Button>
                </div>
            </Dialog>
        </div>
    );
}

const filterTokens = (begin, end) => {
    let state = store.getState().general;

    let tokens = state.tokens.filter(token => {
        return (
            moment(token.begin).isAfter(moment(begin).startOf("day")) &&
            moment(token.begin).isBefore(moment(end).endOf("day")) &&
            token.status === "free"
        );
    });

    return tokens;
};
