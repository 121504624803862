// const { envs, LogLevel } = require("./config");

import {
    abiStore,
    storeRegistryAddress,
    abiStoreRegistry,
    BACKEND_URL,
} from "./contract";
import { ethers, BigNumber } from "ethers";
import { orderBy } from "lodash";
import bs58 from "bs58";
import { ipfsNode, provider } from "../enhancers/createIpfsEnhancer";
import axios from "axios";

// Get abi coder instance
const abiCoder = ethers.utils.defaultAbiCoder;

export function requestFunds(walletAddress) {
    // fetch("https://api.faucet.matic.network/transferTokens", {
    //     method: "POST",
    //     headers: {
    //         "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({
    //         address: walletAddress,
    //         network: "mumbai",
    //         token: "maticToken",
    //     }),
    // })
    //     .then(response => {
    //         return response;
    //     })
    //     .catch(err => console.log(err));

    axios
        .post(
            "https://api.faucet.matic.network/transferTokens",
            {
                address: walletAddress,
                network: "mumbai",
                token: "maticToken",
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        )
        .then(res => {
            console.log(res);
        })
        .catch(e => console.log(e));

    // axios
    //     .post(
    //         "https://daad-2001-b07-6464-bdd1-e13c-62aa-de2f-6afa.ngrok.io/methods/givemeeth",
    //         {
    //             to: walletAddress,
    //         },
    //         {
    //             headers: {
    //                 "Content-Type": "application/json",
    //             },
    //         },
    //     )
    //     .then(res => {
    //         console.log(res);
    //     })
    //     .catch(e => console.log(e));
}

export async function loadTokenToIPFS(storeAddress, walletAddress) {
    const contract = new ethers.Contract(storeAddress, abiStore, provider);
    const { ownedTokens, firstTokenBlock } = await extractOwnedToken(contract);
    const ipfsAddress = await addToIPFS(
        ipfsNode,
        abiCoder,
        ownedTokens,
        walletAddress,
    );

    console.log("Load to IPFS ... Done, ", ipfsAddress);

    const hexString = await contract.getTokensOfOwnerIpfs(walletAddress);

    let buf = Buffer.from(hexString.slice(2), "hex");
    let cid = bs58.encode(buf).toString();

    console.log("CID ==>", cid);
}

export async function extractOwnedToken(contract) {
    const owner = await contract.owner();
    provider.resetEventsBlock(0);

    // let startBlock = 9845330;
    let startBlock = 19759174;
    let endBlock = (await provider.getBlock("latest")).number;

    const chunks = new Array(Math.floor((endBlock - startBlock) / 99999))
        .fill(99999)
        .concat((endBlock - startBlock) % 99999);

    let toMe = [];
    let fromMe = [];

    // Initialize ownedTokens with 0 tokens for the store owner
    let ownedTokens = {
        [owner]: [],
    };

    try {
        for (const [i, ch] of chunks.entries()) {
            endBlock = startBlock + ch;

            //Take all transaction from everyone to me
            toMe = [
                ...toMe,
                ...(await contract.queryFilter(
                    contract.filters.Transfer(null, owner),
                    startBlock,
                    endBlock,
                )),
            ];

            //Take all transaction from me to everyone
            fromMe = [
                ...fromMe,
                ...(await contract.queryFilter(
                    contract.filters.Transfer(owner),
                    startBlock,
                    endBlock,
                )),
            ];

            startBlock = startBlock + ch + 1;
        }

        //Merge fromMe e toMe and order by blockNumber and logIndex
        let transfers = orderBy(
            [...toMe, ...fromMe],
            ["blockNumber", "logIndex"],
        );

        let firstTokenBlock = 0;

        let ownedTokensIndex = {};

        function addToken(to, tokenId) {
            // Create the array if it doesn't exist
            if (ownedTokens[to] === undefined) {
                ownedTokens[to] = [];
            }

            // Store the token index
            ownedTokensIndex[tokenId] = ownedTokens[to].length;

            // Add the token to the array
            ownedTokens[to].push(tokenId);
        }

        function removeToken(from, tokenId) {
            if (ownedTokens[from] !== undefined) {
                let lastTokenIndex = ownedTokens[from].length - 1;
                let tokenIndex = ownedTokensIndex[tokenId];

                if (tokenIndex != lastTokenIndex) {
                    // When the token to delete is the last token, the swap operation is unnecessary
                    let lastTokenId = ownedTokens[from][lastTokenIndex];

                    ownedTokens[from][tokenIndex] = lastTokenId; // Move the last token to the slot of the to-delete token
                    ownedTokensIndex[lastTokenId] = tokenIndex; // Update the moved token's index
                }

                // This also deletes the contents at the last position of the array
                ownedTokens[from].pop();
            } else {
                // console.log("nothing toobject remove");
            }
        }

        for (const event of transfers) {
            let tokenId = event.args.tokenId.toString();
            let tokenIdHex = BigNumber.from(tokenId).toHexString();

            console.log(
                await contract.queryFilter(
                    contract.filters.SlotCreated(tokenIdHex),
                    event.blockNumber,
                    event.blockNumber,
                ),
            );

            // console.log("Token id", tokenId);
            removeToken(event.args.from, tokenId);
            addToken(event.args.to, tokenId);
        }

        return { ownedTokens, firstTokenBlock };
    } catch (err) {
        console.log(err);
        return ownedTokens;
    }
}

//This function take the array of token of the store address encode the data and load on IPFS
async function addToIPFS(node, abiCoder, ownedTokens, walletAddress) {
    //   let addresses = Object.keys(ownedTokens);
    try {
        let addresses = [walletAddress];

        if (addresses.length == 0) {
            return null;
        }

        let res = [];
        for (let address of addresses) {
            // Calculate the abi encode of the token array
            const abiEncoded = abiCoder.encode(
                ["uint256[]"],
                [ownedTokens[walletAddress]],
            );

            for await (const result of node.add(
                Buffer.from(abiEncoded.slice(2), "hex"),
            )) {
                res.push(result.cid.toString());
            }

            // console.log(results);

            // res.push(results);
        }

        for (const ipfsAddress of res) {
            let result = await axios.post(
                // `${BACKEND_URL}/pinToIPFS`,\
                "http://localhost:9300/pinToIPFS",
                {
                    ipfsAddress,
                },
                // {
                //     headers: {
                //         "Access-Control-Allow-Origin": "*",
                //         "Access-Control-Allow-Headers": "Origin",
                //     },
                // },
            );

            console.log("RESULT ==>", result);
        }

        return res;
    } catch (err) {
        console.log(err);
        return [null];
    }
}

export function getContract(wallet, contractAddr, abi) {
    return new ethers.Contract(contractAddr, abi, wallet.connect(provider));
}

export async function getRegistry(wallet) {
    let registry = await getContract(
        wallet,
        storeRegistryAddress,
        abiStoreRegistry,
    );

    return registry.getRegistry().then(res => res);
}

export function mailTo(email, subject, body) {
    if (!email) {
        console.error("Email is mandatory");
    }

    let base = `mailto:${email}`;
    let hasParameters = subject || body;

    return base.concat(
        hasParameters ? "?" : "",
        subject ? `subject=${subject}` : "",
        subject && body ? "&" : "",
        body ? `body=${body}` : "",
    );
}

export const truncStringPortion = (
    str,
    firstCharCount = str.length,
    endCharCount = 0,
    dotCount = 3,
) => {
    let convertedStr = "";
    convertedStr += str.substring(0, firstCharCount);
    convertedStr += ".".repeat(dotCount);
    convertedStr += str.substring(str.length - endCharCount, str.length);
    return convertedStr;
};

export function getFormattedBalance(address) {
    return provider.getBalance(address).then(result => {
        return ethers.utils.formatEther(result).match(/^[0-9]*.[0-9]/);
    });
}

export function getRandom(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

//Post store data to the db
export function postStoreData(id, address, phone, img, blockCreation) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            shopId: id,
            address: address,
            phone: phone,
            img: img,
            blockCreation: blockCreation,
        }),
    };

    fetch(`${BACKEND_URL}/addShopInfo`, requestOptions).catch(error =>
        console.log("error", error),
    );

    // console.log(requestOptions.body);
}

//Get store address by parsing the store creation transaction
export async function getStoreAddress(txHash, contract) {
    let receipt = await provider.getTransactionReceipt(txHash);

    const storeAddress = contract.interface.parseLog(receipt.logs[2]);

    return storeAddress.args.storeAddress;
}

//Check if balance is not zero
export async function checkBalance(user) {
    let currentBalance = await user.connect(provider).getBalance();
    console.log(currentBalance.toString(), await currentBalance.isZero());
    return await currentBalance.isZero();
}

//Loops through an array of events to find the event where the current user created a new Store contract and returns the store address
export async function getStoreAddressWithEventsLogs(logs, userAddress) {
    let storeAddress = "";

    for (let index = 0; index < logs.length; index++) {
        let receipt = await provider.getTransactionReceipt(
            logs[index].transactionHash,
        );
        if (receipt.from === userAddress) {
            storeAddress = receipt.logs[0].address;

            return storeAddress;
        }
    }

    return null;
}

export async function getCachedData(ipfsNode, hexString) {
    // Create Abi Coder instance
    const abiCoder = ethers.utils.defaultAbiCoder;

    try {
        let buf = Buffer.from(hexString.slice(2), "hex");
        // console.log(buf);
        let cid = bs58.encode(buf).toString();

        console.log(cid);
        let stream = ipfsNode.cat(cid);

        // console.log("after cat():", stream);

        let tdata = [];
        for await (const chunk of stream) {
            tdata.push(chunk);
        }

        const abiDecoded = abiCoder.decode(
            ["uint256[]"],
            "0x" + tdata[0].toString("hex"),
        );

        return abiDecoded[0].map(el => el.toString());
    } catch (error) {
        return error;
    }
}

export async function getReservedTokens(
    storeTokenIds,
    store,
    storeOwnerAddress,
) {
    let bookedTokensArray = [];
    for (let i = 0; i < storeTokenIds.length; i++) {
        const tokenId = storeTokenIds[i];

        const tokenOwnerAddress = await store.ownerOf(tokenId.toString());

        if (tokenOwnerAddress !== storeOwnerAddress) {
            console.log("Hey this is a booked token! ", tokenId);

            const slot = await store.timeSlots(tokenId.toString());

            const bt = {
                id: tokenId.toString(),
                begin: slot.begin.toNumber() * 1000,
                end: slot.end.toNumber() * 1000,
                index: slot.index.toNumber(),
            };
            bookedTokensArray.concat(bt);
        } else {
            console.log("This not a booked token! ", tokenId);
        }
    }

    console.log(bookedTokensArray);

    return bookedTokensArray;
}
