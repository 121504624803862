import { walletConstants } from "../constants/wallet.constants";
import produce from "immer";

const initialstate = { node: null };

export const ipfs = (state = initialstate, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case walletConstants.CREATE_NODE:
        draftState.node = action.payload;
        break;
      default:
        return draftState;
    }
  });
};
