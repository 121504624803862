import { applyMiddleware, createStore, compose } from "redux";
import { createEpicMiddleware } from "redux-observable";
import { persistReducer, persistStore } from "redux-persist";
import { rootEpic } from "../epics";
import rootReducer from "../reducers/index";
import { createIpfsEnhancer } from "../../enhancers/createIpfsEnhancer";
import storage from "redux-persist/lib/storage";
import { transform } from "./transform";

const persistConfig = {
  key: "rootReducer",
  storage,
  transforms: [transform],
};

const epicMiddleware = createEpicMiddleware({
  dependencies: {
    // config,
  },
});

const middlewares = [epicMiddleware];

const persistedReducer = persistReducer(persistConfig, rootReducer);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middlewares), createIpfsEnhancer())
);

export let persistor = persistStore(store);

epicMiddleware.run(rootEpic);
