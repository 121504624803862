import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import "./index.css";
import { ThemeProvider } from "@material-ui/core/styles";
import { main } from "./material-ui";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store/index";

import "./enhancers/createIpfsEnhancer";

ReactDOM.render(
    <React.Fragment>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Router>
                    <ThemeProvider theme={main}>
                        <App />
                    </ThemeProvider>
                </Router>
            </PersistGate>
        </Provider>
    </React.Fragment>,
    document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
