import React from "react";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

import "./header.scss";

export default function Header({ pageTitle, toggleDrawer, nextStep }) {
  return (
    <AppBar position="absolute" className="topbar">
      <Toolbar>
        <IconButton
          className="step-0"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={() => {
            nextStep();
            toggleDrawer(true);
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6"> {pageTitle} </Typography>
      </Toolbar>
    </AppBar>
  );
}
