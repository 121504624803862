import { combineEpics, ofType } from "redux-observable";
import { from, Observable, of } from "rxjs";
import { mergeMap } from "rxjs/operators";

import { walletConstants } from "../constants/wallet.constants";

import { isEmpty } from "lodash";

import { ethers } from "ethers";
import {
    abiStore,
    address_burn,
    storeFactoryAddress,
    abiStoreFactory,
} from "../../config/contract";

import { ipfsNode, provider } from "../../enhancers/createIpfsEnhancer";
import {
    requestFunds,
    getRegistry,
    postStoreData,
    getStoreAddress,
    checkBalance,
    getStoreAddressWithEventsLogs,
    getReservedTokens,
    getCachedData,
} from "../../config/utility";

import {
    loadFailure,
    loadSuccess,
    login,
    loginStore,
    loadToken,
    loadTokens,
    loadBookedTokens,
    deleteToken,
    userLogout,
    loadSingleToken,
} from "../actions/index";

import { gql, request } from "graphql-request";

const walletCreateEpic = (action$, state$) => {
    return action$.pipe(
        ofType(walletConstants.WALLET_CREATE),
        mergeMap(() => {
            const fun = async () => {
                try {
                    let wallet = ethers.Wallet.createRandom().connect(provider);
                    console.log(wallet);
                    requestFunds(wallet.address);
                    return wallet;
                } catch (err) {
                    return { err };
                }
            };

            return from(fun()).pipe(
                mergeMap(result => {
                    if (result.err) {
                        return of(loadFailure());
                    } else {
                        return of(login(result), loadSuccess());
                    }
                }),
            );
        }),
    );
};

//TODO: Implement address in store contract
//Create store through a transaction to StoreFactory with name and token ticker
const storeCreateEpic = (action$, state$) => {
    return action$.pipe(
        ofType(walletConstants.STORE_CREATE),
        mergeMap(({ payload: { name, location, phone, img, ticker } }) => {
            const user = state$.value.general.user;

            //Store factory
            const contract = new ethers.Contract(
                storeFactoryAddress,
                abiStoreFactory,
                user,
            );

            const fun = async () => {
                while (await checkBalance(user)) {
                    console.log("waiting for funds for", user.address);
                    requestFunds(user.address);

                    await new Promise(r => setTimeout(r, 2000));
                }

                try {
                    let txData = await contract.createNewStore(name, ticker, {
                        gasPrice: ethers.utils.parseUnits("5.0", "gwei"),
                        gasLimit: 6000000,
                    });
                    let receipt = await txData.wait();
                    const storeAddress = await getStoreAddress(
                        txData.hash,
                        contract,
                    );

                    const store = new ethers.Contract(
                        storeAddress,
                        abiStore,
                        user,
                    );

                    const storeName = await store.name();
                    const address = store.address;

                    postStoreData(
                        storeAddress,
                        location,
                        phone,
                        img,
                        receipt.blockNumber,
                    );
                    return { store: store, name: storeName, address: address };
                } catch (error) {
                    console.log(error);
                    return { error };
                }
            };
            return from(fun()).pipe(
                mergeMap(result => {
                    if (result.error) {
                        return of(loadFailure());
                    } else {
                        return of(loginStore(result), loadSuccess());
                    }
                }),
            );
        }),
    );
};

const walletLoginEpic = (action$, state$) => {
    return action$.pipe(
        ofType(walletConstants.WALLET_LOGIN),
        mergeMap(({ payload: { mnemonic } }) => {
            let account =
                ethers.Wallet.fromMnemonic(mnemonic).connect(provider);
            let storeAddress = "";
            let store;

            const fun = async () => {
                //Get all NewStore events log

                // let startBlock = 9845330;
                let startBlock = 15000000;
                let endBlock = (await provider.getBlock("latest")).number;

                const chunks = new Array(
                    Math.floor((endBlock - startBlock) / 99999),
                )
                    .fill(99999)
                    .concat((endBlock - startBlock) % 99999);
                let logs = [];

                for (const [i, ch] of chunks.entries()) {
                    endBlock = startBlock + ch;

                    logs = [
                        ...logs,
                        ...(await provider.getLogs({
                            fromBlock: startBlock,
                            toBlock: endBlock,
                            topics: [
                                "0xae1a294802b0c6ad4e9faee46f5f69149bbe37cee0662a1350609ec0e34e54c2",
                            ],
                        })),
                    ];

                    startBlock = startBlock + ch + 1;
                }

                storeAddress = await getStoreAddressWithEventsLogs(
                    logs,
                    account.address,
                );

                if (storeAddress) {
                    // console.log("storeaddress pre contract", storeAddress);
                    store = new ethers.Contract(
                        storeAddress,
                        abiStore,
                        account,
                    );

                    const name = await store.name();
                    const address = store.address;

                    const registry = await getRegistry(account);

                    return {
                        account,
                        shop: {
                            store: store,
                            name: name,
                            address: address,
                            trusted: registry[0].includes(storeAddress),
                        },
                    };
                } else {
                    return { error: "nothing to load" };
                }
            };
            return from(fun()).pipe(
                mergeMap(result => {
                    if (result.error) {
                        return of(loadFailure());
                    } else {
                        return of(
                            login(result.account),
                            loginStore(result.shop),
                            loadSuccess(),
                        );
                    }
                }),
            );
        }),
    );
};

// const loginStoreEpic = (action$, state$) => {
//   return action$.pipe(
//     ofType(walletConstants.LOGIN_STORE_REQUEST),
//     mergeMap(({payload: {}}) => {
//       let store = new ethers.Contract(
//         storeAddress,
//         abiStore,
//         ownerWallet.connect(provider)
//       );
//       const fun = async () => {
//         try {
//           const name = await store.name();
//           return { name };
//         } catch (error) {
//           return { error };
//         }
//       };
//       return from(fun()).pipe(
//         mergeMap((result) => {
//           if (result.error) {
//             return of(loadFailure());
//           } else {
//             return of(loadSuccess());
//           }
//         })
//       );
//     })
//   );
// };

//Load all tokens with IPFS

const loadIPFSOwnedTokensEpic = (action$, state$) => {
    return action$.pipe(
        ofType(walletConstants.LOAD_IPFS_TOKEN),
        mergeMap(({ payload: { storeAddress, ownerWallet } }) => {
            const fun = async () => {
                if (ipfsNode) {
                    try {
                        const contract = new ethers.Contract(
                            storeAddress,
                            abiStore,
                            ownerWallet.connect(provider),
                        );

                        let ipfsProof = await contract.getTokensOfOwnerIpfs(
                            ownerWallet.address,
                        );

                        let tokenIds = await getCachedData(ipfsNode, ipfsProof);

                        for (let i = 0; i < tokenIds.length; i++) {
                            const tokenId = tokenIds[i];

                            const owner = await contract.ownerOf(
                                tokenId.toString(),
                            );
                            const slot = await contract.timeSlots(
                                tokenId.toString(),
                            );
                            let bt = {};

                            if (owner !== ownerWallet.address) {
                                if (owner === address_burn) {
                                    bt = {
                                        id: tokenId,
                                        begin: slot.begin.toNumber() * 1000,
                                        end: slot.end.toNumber() * 1000,
                                        index: slot.index.toNumber(),
                                        status: "burned",
                                    };
                                } else {
                                    bt = {
                                        id: tokenId,
                                        begin: slot.begin.toNumber() * 1000,
                                        end: slot.end.toNumber() * 1000,
                                        index: slot.index.toNumber(),
                                        status: "booked",
                                    };
                                    console.log(
                                        "Hey this is a booked token! ",
                                        bt,
                                    );
                                }
                            } else {
                                bt = {
                                    id: tokenId,
                                    begin: slot.begin.toNumber() * 1000,
                                    end: slot.end.toNumber() * 1000,
                                    index: slot.index.toNumber(),
                                    status: "free",
                                };
                            }

                            return { token: bt, index: i };
                        }
                    } catch (error) {
                        return { error };
                    }
                } else {
                    return { error: "no ipfs node" };
                }
            };
            return from(fun()).pipe(
                mergeMap(result => {
                    if (result.error) {
                        return of(loadFailure());
                    } else {
                        return of(loadToken(result), loadSuccess());
                    }
                }),
            );
        }),
    );
};

const getAllTokensEpic = (action$, state$) => {
    return action$.pipe(
        ofType(walletConstants.GET_ALL_TOKEN),
        mergeMap(({ payload: { store, storeOwnerAddress } }) => {
            const fun = new Observable(async tokens => {
                let totSupply = (await store.totalSupply()).toNumber();

                for (let i = 0; i < totSupply; i++) {
                    const tokenId = await store.tokenByIndex(i);

                    const owner = await store.ownerOf(tokenId.toString());
                    const slot = await store.timeSlots(tokenId.toString());
                    let bt = {};

                    console.log(tokenId.toString(), owner);

                    if (owner !== storeOwnerAddress) {
                        if (owner === address_burn) {
                            bt = {
                                id: tokenId.toString(),
                                begin: slot.begin.toNumber() * 1000,
                                end: slot.end.toNumber() * 1000,
                                index: slot.index.toNumber(),
                                status: "burned",
                            };
                        } else {
                            bt = {
                                id: tokenId.toString(),
                                begin: slot.begin.toNumber() * 1000,
                                end: slot.end.toNumber() * 1000,
                                index: slot.index.toNumber(),
                                status: "booked",
                            };
                            // console.log("Hey this is a booked token! ", bt);
                        }
                    } else {
                        bt = {
                            id: tokenId.toString(),
                            begin: slot.begin.toNumber() * 1000,
                            end: slot.end.toNumber() * 1000,
                            index: slot.index.toNumber(),
                            status: "free",
                        };
                    }

                    tokens.next({ token: bt });
                }
                tokens.complete();
            });

            return from(fun).pipe(
                mergeMap(result => {
                    if (result.err) {
                        return of(loadFailure());
                    } else {
                        return of(loadToken(result), loadSuccess());
                        // return of(loadTokens(result), loadSuccess());
                    }
                }),
            );
        }),
    );
};

const loadBookedTokensEpic = (action$, state$) => {
    return action$.pipe(
        ofType(walletConstants.LOAD_BOOKED_TOKENS_REQUEST),
        mergeMap(({ payload: { storeAddress, ownerWallet } }) => {
            if (!storeAddress || !ownerWallet) return of(loadFailure());

            const instance = new ethers.Contract(
                storeAddress,
                abiStore,
                ownerWallet.connect(provider),
            );

            const fun = async () => {
                try {
                    let ipfsProof = await instance.getTokensOfOwnerIpfs(
                        ownerWallet.address,
                    );

                    let tokenIds = await getCachedData(ipfsNode, ipfsProof);

                    const bookedTokens = getReservedTokens(
                        tokenIds,
                        instance,
                        ownerWallet.address,
                    );

                    return { bookedTokens };
                } catch (error) {
                    return { error };
                }
            };

            return from(fun()).pipe(
                mergeMap(result => {
                    if (result.err) {
                        return of(loadFailure());
                    } else {
                        return of(loadBookedTokens(result.bookedTokens));
                    }
                }),
            );
        }),
    );
};

const deleteTokenEpic = (action$, state$) => {
    return action$.pipe(
        ofType(walletConstants.DELETE_TOKEN_REQUEST),
        mergeMap(({ payload: { tokenId } }) => {
            if (!tokenId) return of(loadFailure());

            let state = state$.value.general;
            let contract = new ethers.Contract(
                state.userStore.address,
                abiStore,
                state.user.connect(provider),
            );

            const fun = async () => {
                try {
                    // const tx = await contract.burn(tokenId);
                    const tx = await contract.transferFrom(
                        state.user.address,
                        address_burn,
                        tokenId,
                    );
                    console.log(await tx.wait());
                    return { tokenId };
                } catch (error) {
                    console.log(
                        "Token with id ",
                        tokenId,
                        " failed deletion with the following error: ",
                        error,
                    );
                    return { error };
                }
            };
            return from(fun()).pipe(
                mergeMap(result => {
                    if (result.error) {
                        return of(loadFailure());
                    } else {
                        return of(deleteToken(result.tokenId), loadSuccess());
                    }
                }),
            );
        }),
    );
};

const getSingleTokenEpic = (action$, state$) => {
    return action$.pipe(
        ofType(walletConstants.GET_SINGLE_TOKEN),
        mergeMap(({ payload: { tokenId, store, storeOwnerAddress } }) => {
            const fun = async () => {
                if (tokenId && !isEmpty(store)) {
                    const owner = await store.ownerOf(tokenId);
                    const slot = await store.timeSlots(tokenId);
                    let bt = {};

                    if (owner !== storeOwnerAddress) {
                        if (owner === address_burn) {
                            bt = {
                                id: tokenId.toString(),
                                begin: slot.begin.toNumber() * 1000,
                                end: slot.end.toNumber() * 1000,
                                index: slot.index.toNumber(),
                                status: "burned",
                            };
                        } else {
                            bt = {
                                id: tokenId.toString(),
                                begin: slot.begin.toNumber() * 1000,
                                end: slot.end.toNumber() * 1000,
                                index: slot.index.toNumber(),
                                status: "booked",
                            };
                        }
                    } else {
                        bt = {
                            id: tokenId.toString(),
                            begin: slot.begin.toNumber() * 1000,
                            end: slot.end.toNumber() * 1000,
                            index: slot.index.toNumber(),
                            status: "free",
                        };
                    }

                    // console.log(bt);
                    return { token: bt };
                } else {
                    return { token: null };
                }
            };
            return from(fun()).pipe(
                mergeMap(result => {
                    if (result.err) {
                        return of(loadFailure());
                    } else {
                        if (result.token) {
                            return of(loadSingleToken(result), loadSuccess());
                        } else {
                            return of(loadSuccess());
                        }
                    }
                }),
            );
        }),
    );
};

const logoutEpic = (action$, state$) => {
    return action$.pipe(
        ofType(walletConstants.LOGOUT),
        mergeMap(() => {
            try {
                state$.value.general.userStore.removeAllListeners();
                return of(userLogout(), loadSuccess());
            } catch (error) {
                return of(loadFailure());
            }
        }),
    );
};

export default combineEpics(
    walletCreateEpic,
    storeCreateEpic,
    walletLoginEpic,
    loadIPFSOwnedTokensEpic,
    loadBookedTokensEpic,
    deleteTokenEpic,
    getAllTokensEpic,
    getSingleTokenEpic,
    logoutEpic,
);
