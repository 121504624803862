import React, { Component } from "react";

import { connect } from "react-redux";
import {
    loadIPFSOwnedTokens,
    deleteTokenRequest,
    getAllTokens,
    getSingleToken,
} from "../redux/actions";

import { groupBy } from "lodash";
import moment from "moment";

import Snackbar from "../components/snackbar/snackbar";
import Calendar from "react-calendar-material";
import Spinner from "../components/spinner/spinner";
import {
    TimeButton,
    TokenDetails,
    MultipleTokensDetails,
} from "../components/tokenComponents/TokenComponents";

import "../style/calendario.scss";
import { COLORS } from "../config/constants";

class Calendario extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showTokenDetails: false,
            showMultipleTokensDetails: false,
            showSuccess: false,
            currentDate: moment(),
            tokens: [],
        };

        this.changeMonth = this.changeMonth.bind(this);
    }

    setDateFilter = value => {
        this.setState({
            currentDate: moment(value),
        });
    };

    async componentWillMount() {
        // const totalSupply = (
        //     await this.props.userStore.totalSupply()
        // ).toNumber();

        // console.log(this.props.tokens.length, totalSupply);

        // this.props.tokens.length === totalSupply
        //     ? this.props.loadIPFSOwnedTokens({
        //           storeAddress: this.props.storeAddress,
        //           user: this.props.user,
        //       })
        //     :
        this.props.getAllTokens({
            store: this.props.userStore,
            storeOwnerAddress: this.props.user.address,
        });
    }

    componentDidMount() {
        this.setTokens();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.tokens !== this.props.tokens ||
            prevState.currentDate !== this.state.currentDate
        ) {
            this.setTokens();
        }
    }

    changeMonth = i => {
        this.setState({ currentDate: this.state.currentDate.add(i, "month") });
    };

    setTokens = () => {
        this.setState({
            tokens: Object.values(
                groupBy(
                    this.props.tokens?.filter(token => {
                        return moment(token.begin).isSame(
                            this.state.currentDate,
                            "day",
                        );
                    }),
                    "begin",
                ),
            ),
        });
    };

    openDetailsDialog = tokensData => {
        if (tokensData.length > 1) {
            this.setState({ showMultipleTokensDetails: tokensData });
        } else {
            this.setState({
                showTokenDetails: tokensData[0],
                showMultipleTokensDetails: false,
            });
        }
    };

    tokenBurn = async tokenId => {
        this.props.deleteTokenRequest({ tokenId });
        this.setState({ showMultipleTokensDetails: false });
        this.setState({ showSuccess: true });
        this.setState({ showTokenDetails: false });
    };

    render() {
        return (
            <div className="calendario">
                <Calendar
                    border
                    accentColor={COLORS.secondary}
                    orientation={"flex-col"}
                    showHeader={false}
                    onDatePicked={d => {
                        this.setDateFilter(d);
                    }}
                    onChangeMonth={this.changeMonth}
                    lang="ita"
                    eventsBool={[
                        ...Array(this.state.currentDate.daysInMonth()).keys(),
                    ].map(day =>
                        this.props.tokens?.filter(slot => {
                            return (
                                moment
                                    .unix(slot.begin / 1000)
                                    .isSame(this.state.currentDate, "month") &&
                                moment.unix(slot.begin / 1000).date() - 1 ===
                                    day
                            );
                        }).length
                            ? true
                            : false,
                    )}
                />
                <div className="slots">
                    <div className="slots-container">
                        {this.state.tokens.map((slot, i) => (
                            <TimeButton
                                key={i}
                                begin={slot[0].begin}
                                end={slot[0].end}
                                status={slot[0].status}
                                tokenId={slot[0].tokenId}
                                tokens={slot}
                                handleClick={() => this.openDetailsDialog(slot)}
                            />
                        ))}
                    </div>
                </div>

                {this.state.showTokenDetails && (
                    <TokenDetails
                        handleTokenBurn={this.tokenBurn}
                        token={this.state.showTokenDetails}
                        toggleDialog={() =>
                            this.setState({
                                showTokenDetails: false,
                            })
                        }
                    />
                )}
                {this.state.showMultipleTokensDetails && (
                    <MultipleTokensDetails
                        handleTokenClick={this.openDetailsDialog}
                        handleMultipleTokensBurn={this.tokenBurn}
                        tokens={this.state.showMultipleTokensDetails}
                        toggleDialog={() =>
                            this.setState({
                                showMultipleTokensDetails: false,
                            })
                        }
                    />
                )}
                <Spinner show={this.props.loading} />
                <Snackbar
                    open={this.state.showSuccess}
                    onClose={() => this.setState({ showSuccess: false })}
                    status="success"
                    label="Eliminazione tokens avvenuta con successo!"
                />
            </div>
        );
    }
}

function mapStateToProps({ general }) {
    return {
        loading: general.loading,
        user: general.user,
        userStore: general.userStore,
        storeAddress: general.storeAddress,
        tokens: general.tokens,
    };
}

export default connect(mapStateToProps, {
    loadIPFSOwnedTokens,
    deleteTokenRequest,
    getAllTokens,
    getSingleToken,
})(Calendario);

// import React, { Component } from "react";

// import { connect } from "react-redux";
// import { loadIPFSOwnedTokens, deleteTokenRequest, getAllTokens, getSingleToken } from "../actions/wallet.actions";

// import Snackbar from "@material-ui/core/Snackbar";
// import MuiAlert from "@material-ui/lab/Alert";

// import Calendar from "react-calendar-material";
// import moment from "moment";

// import { TimeButton, TokenDetails } from "../components/TokenComponents";
// import Spinner from "../components/spinner/spinner";
// import styleVariables from "../style/variables.scss";

// class Calendario extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       showTokenDetails: false,
//       showSuccess: false,
//       currentDate: moment(),
//       tokens: [],
//     };

//     this.setDateFilter = this.setDateFilter.bind(this);
//   }

//   setDateFilter = (value) => {
//     this.setState({
//       currentDate: moment(value),
//     });
//   };

//   async componentWillMount() {
//     const totalSupply = (await this.props.userStore.totalSupply()).toNumber();

//     // console.log("totsup: ", totalSupply);
//     // console.log("cache sup: ", this.props.tokens.length);

//     this.props.tokens.length === totalSupply
//       ? this.props.loadIPFSOwnedTokens(this.props.storeAddress, this.props.user)
//       : this.props.getAllTokens(this.props.userStore, this.props.user.address);
//   }

//   // componentDidUpdate(prevProps) {
//   //   if (prevProps.tokens !== this.props.tokens) {
//   //     console.log("Props updated");
//   //   }
//   // }

//   openDetailsDialog = (tokenData) => {
//     this.setState({ showTokenDetails: tokenData });
//   };

//   //TODO: setTimeout to be removed, check that deleteTokenRequest is confirmed on the blockchain before calling getSingleToken
//   tokenBurn = async (tokenId) => {
//     this.props.deleteTokenRequest(tokenId);
//     this.setState({ showSuccess: true });
//     this.setState({ showTokenDetails: false });
//   };

//   render() {
//     return (
//       <div className="calendario">
//         <Calendar
//           accentColor={styleVariables.secondary}
//           orientation={"flex-col"}
//           showHeader={false}
//           onDatePicked={(d) => {
//             this.setDateFilter(d);
//           }}
//           lang="ita"
//           // style={{ flex: "2" }}
//           eventsBool={[...Array(this.state.currentDate.daysInMonth()).keys()].map((day) =>
//             this.props.slots?.filter((slot) => {
//               return (
//                 moment.unix(slot.begin).isSame(this.state.currentDate, "month") &&
//                 moment.unix(slot.begin).date() - 1 === day
//               );
//             }).length
//               ? true
//               : false
//           )}
//         />
//         <div className="slots">
//           <div className=" slots-container">
//             {this.props.tokens
//               .filter((slot) => {
//                 return moment(slot.begin).isSame(this.state.currentDate, "day");
//               })
//               .map((slot, i) => (
//                 <TimeButton
//                   key={i}
//                   begin={slot.begin}
//                   end={slot.end}
//                   status={slot.status}
//                   tokenId={slot.tokenId}
//                   handleClick={() => this.openDetailsDialog(slot)}
//                 />
//               ))}
//           </div>
//         </div>

//         {this.state.showTokenDetails && (
//           <TokenDetails
//             handleTokenBurn={this.tokenBurn}
//             token={this.state.showTokenDetails}
//             toggleDialog={() =>
//               this.setState({
//                 showTokenDetails: false,
//               })
//             }
//           />
//         )}

//         {/* <Backdrop style={{ zIndex: "100" }} open={this.props.loading}>
//           <CircularProgress color="inherit" />
//         </Backdrop> */}
//         <Spinner show={this.props.loading} />
//         <Snackbar
//           open={this.state.showSuccess}
//           autoHideDuration={4000}
//           onClose={() => this.setState({ showSuccess: false })}
//         >
//           <MuiAlert
//             elevation={6}
//             variant="filled"
//             onClose={() => this.setState({ showSuccess: false })}
//             severity="success"
//           >
//             Token eliminato con successo!
//           </MuiAlert>
//         </Snackbar>
//       </div>
//     );
//   }
// }

// function mapStateToProps({ general }) {
//   return {
//     loading: general.loading,
//     user: general.user,
//     userStore: general.userStore,
//     storeAddress: general.storeAddress,
//     tokens: general.tokens,
//   };
// }

// export default connect(mapStateToProps, {
//   loadIPFSOwnedTokens,
//   deleteTokenRequest,
//   getAllTokens,
//   getSingleToken,
// })(Calendario);
