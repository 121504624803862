export const EMAIL_KNOBS = "info@knobs.it";
export const EMAIL_BCODE = "info@bcode.cloud";

export const EMAIL_SUBJECT = "Verifca Prenotazione - ";

export const ADDITIONAL_TEXT = [
    "...stiamo allineando i pianeti.",
    //   "...momento, momento, momento!",
    "...potrebbe volerci un po' di tempo.",
    "...stiamo aggiustando l'inclinazione dell'asse terrestre.",
    "...per le cose più belle ci vuole sempre un po' di pazienza.",
];

export const COLORS = {
    primary: "#1d1d1b",
    secondary: "#b8d04e",
    lighterSecondary: "#b8d04e",
    lighterTransparentSecondary: "#b8d04e33",
    error: "#e4584e",
    success: "#4caf50",
};
