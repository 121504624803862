import Dashboard from "../pages/Dashboard";
import CreazioneStore from "../pages/CreazioneStore";
import GestioneToken from "../pages/GestioneToken";
import CreazioneToken from "../pages/CreazioneToken";
import Calendario from "../pages/Calendario";
import Settings from "../pages/settings/settings";
import Login from "../pages/Login";

export const routes = [
  {
    path: "/",
    component: Dashboard,
    pageTitle: "Dashboard",
    private: true,
  },
  {
    path: "/creazione-store",
    component: CreazioneStore,
    pageTitle: "Creazione Store",
    private: false,
  },
  {
    path: "/calendario",
    component: Calendario,
    pageTitle: "Calendario",
    private: true,
  },
  {
    path: "/gestione-token",
    component: GestioneToken,
    pageTitle: "Gestione Token",
    private: true,
  },
  {
    path: "/gestione-token/creazione-token/:begin/:end",
    component: CreazioneToken,
    pageTitle: "Creazione nuovi Token",
    private: true,
  },
  {
    path: "/settings",
    component: Settings,
    pageTitle: "Impostazioni",
    private: true,
  },
  {
    path: "/login",
    component: Login,
    pageTitle: "Login",
    private: false,
  },
];
