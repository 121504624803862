import { walletConstants } from "../constants/wallet.constants";

export function walletCreate() {
  return { type: walletConstants.WALLET_CREATE };
}

export function login(payload) {
  return { type: walletConstants.LOGIN, payload };
}

export function storeCreate(payload) {
  return { type: walletConstants.STORE_CREATE, payload };
}

export function walletLogin(payload) {
  return { type: walletConstants.WALLET_LOGIN, payload };
}

export function getAllTokens(payload) {
  return { type: walletConstants.GET_ALL_TOKEN, payload };
}

export function getSingleToken(payload) {
  return {
    type: walletConstants.GET_SINGLE_TOKEN,
    payload,
  };
}

export function loginStoreRequest(payload) {
  return { type: walletConstants.LOGIN_STORE_REQUEST, payload };
}

export function loginStore(payload) {
  return { type: walletConstants.LOGIN_STORE, payload };
}

export function loadIPFSOwnedTokens(payload) {
  return { type: walletConstants.LOAD_IPFS_TOKEN, payload };
}

export function deleteTokenRequest(payload) {
  return { type: walletConstants.DELETE_TOKEN_REQUEST, payload };
}

export function deleteToken(payload) {
  return { type: walletConstants.DELETE_TOKEN, payload };
}

export function logout() {
  return { type: walletConstants.LOGOUT };
}

export function userLogout(payload) {
  return { type: walletConstants.USER_LOGOUT, payload };
}

export function loadSingleToken(payload) {
  return { type: walletConstants.LOAD_SINGLE_TOKEN, payload };
}

export function loadToken(payload) {
  return { type: walletConstants.LOAD_TOKEN, payload };
}

export function loadTokens(payload) {
  return { type: walletConstants.LOAD_TOKENS, payload };
}

export function loadFailure() {
  return { type: walletConstants.LOAD_FAILURE };
}

export function loadSuccess() {
  return { type: walletConstants.LOAD_SUCCESS };
}

export function loadBookedTokensRequest(payload) {
  return { type: walletConstants.LOAD_BOOKED_TOKENS_REQUEST, payload };
}
export function loadBookedTokens(payload) {
  return { type: walletConstants.LOAD_BOOKED_TOKENS, payload };
}
