import React, { Component } from "react";

import * as Actions from "../redux/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Link } from "react-router-dom";
import Moment from "moment";
import { extendMoment } from "moment-range";

import TextField from "@material-ui/core/TextField";
import Slider from "@material-ui/core/Slider";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";

import { ethers } from "ethers";
import { abiStore } from "../config/contract";
import Spinner from "../components/spinner/spinner";
import { IconButton } from "@material-ui/core";

import { loadTokenToIPFS } from "../config/utility";
import { provider } from "../enhancers/createIpfsEnhancer";

const moment = extendMoment(Moment);

const marks = [
    {
        value: 5,
        label: "5",
    },
    {
        value: 15,
        label: "15",
    },
    {
        value: 30,
        label: "30",
    },
    {
        value: 60,
        label: "60",
    },
    {
        value: 90,
        label: "90",
    },
    {
        value: 120,
        label: "120",
    },
];

function valueLabelFormat(value) {
    return value + "m";
}

class CreazioneToken extends Component {
    constructor(props) {
        super(props);

        // this.account = new ethers.Wallet.fromMnemonic(
        //   "mango catalog iron spring title clever weird pepper practice solution canyon agent"
        // );

        // this.account = this.props.user;

        //Store Contract on testnet.knobs
        // this.storeContract = new ethers.Contract(
        //   "0xa10dA03AbF8753599433eA4004626956f88bAb3b",
        //   abiStore,
        //   this.account.connect(provider)
        // );

        this.state = {
            showDialog: false,
            showLoader: false,
            showSuccess: false,
            showError: false,
            slots: {},
            form: {
                launch: true,
                index: 1,
                schedule: {
                    opening: "08:00",
                    launchClosing: "12:30",
                    launchOpening: "13:30",
                    closing: "18:00",
                    steps: 60,
                },
            },
        };
    }

    reserveSlot = async slots => {
        console.log(slots);
        this.setState({ showDialog: false });
        this.setState({ showLoader: true });

        let currNonce = await this.props.user
            .connect(provider)
            .getTransactionCount();

        let results = slots.map(async (slot, index) => {
            let txData = await this.props.userStore.mintWithData(
                slot.begin,
                slot.end,
                slot.index,
                {
                    nonce: currNonce + index,
                    gasPrice: ethers.utils.parseUnits("6.0", "gwei"),
                    gasLimit: 6000000,
                },
            );

            try {
                const confirmation = await txData.wait();
            } catch (error) {
                console.log(error);
                this.setState({ showError: true });
            }
        });

        //TODO: after all promise will be resolved, start ipfs caching
        Promise.all(results).then(
            resolve => {
                loadTokenToIPFS(
                    this.props.userStore.address,
                    this.props.user.address,
                );
            },
            reason => {
                console.log("Token creation rejected...", reason);
            },
        );

        this.setState({ showSuccess: true });
        this.setState({ showLoader: false });

        return results;
    };

    async generateTokens(
        dateStart,
        dateEnd,
        dayStart,
        dayEnd,
        launchStart,
        launchEnd,
        slotLength,
    ) {
        //Set selected dates range
        let dateRange = moment.range(
            moment(dateStart, "YYYY-MM-DD"),
            moment(dateEnd, "YYYY-MM-DD"),
        );

        let generatedSlots = [];

        //Iterate through every day in the selected range
        for (let day of dateRange.by("days")) {
            console.log("Day: ", day.format("YYYY-MM-DD")); //Log current day

            const dayStartDate = moment(
                day.format("YYYY-MM-DD").toString() + " " + dayStart,
            );
            const dayEndDate = moment(
                day.format("YYYY-MM-DD").toString() + " " + dayEnd,
            );

            if (this.state.form.launch) {
                const launchStartDate = moment(
                    day.format("YYYY-MM-DD").toString() + " " + launchStart,
                );
                const launchEndDate = moment(
                    day.format("YYYY-MM-DD").toString() + " " + launchEnd,
                );

                if (
                    dayStartDate.isAfter(launchEndDate) ||
                    dayEndDate.isBefore(launchStartDate)
                ) {
                    //Set range for all day
                    let singleRange = moment.range(dayStartDate, dayEndDate);

                    //Iterate through every slot in all day
                    generatedSlots.push(
                        ...this.generateSlotsFromRange(singleRange, slotLength),
                    );
                } else {
                    //Set morning range
                    let morningRange = moment.range(
                        dayStartDate,
                        launchStartDate,
                    );

                    //Set afternoon range
                    let afternoonRange = moment.range(
                        launchEndDate,
                        dayEndDate,
                    );

                    //Iterate through every afternoon and morning slot
                    generatedSlots.push(
                        ...this.generateSlotsFromRange(
                            morningRange,
                            slotLength,
                        ),
                        ...this.generateSlotsFromRange(
                            afternoonRange,
                            slotLength,
                        ),
                    );
                }
            } else {
                //Set range for all day
                let singleRange = moment.range(dayStartDate, dayEndDate);

                //Iterate through every slot in all day
                generatedSlots.push(
                    ...this.generateSlotsFromRange(singleRange, slotLength),
                );
            }
        }

        this.setState({ slots: generatedSlots });
        this.setState({ showDialog: true });
    }

    generateSlotsFromRange(timeRange, slotLength) {
        //TODO: avoid generating a slot overlapping on the launch time range

        let array = Array.from(
            timeRange.by("minutes", {
                excludeEnd: true,
                step: slotLength,
            }),
        );
        let slots = [];

        for (let index = 0; index < this.state.form.index; index++) {
            slots = [
                ...slots,
                ...array.map(slot => ({
                    begin: slot.unix(),
                    end: moment(slot).add("minutes", slotLength).unix(),
                    index: index,
                })),
            ];
        }

        return slots;
    }

    render() {
        return (
            <div className="creazione-token">
                <Link to={"/gestione-token"} style={{ textDecoration: "none" }}>
                    <div className="dates">
                        <IconButton className="icon">
                            <ArrowBackIos />
                        </IconButton>
                        <h3>
                            dal{" "}
                            <b>
                                {moment(
                                    this.props.match.params.begin,
                                    "YYYY-MM-DD",
                                ).format("DD/MM/YYYY")}
                            </b>{" "}
                            al{" "}
                            <b>
                                {moment(
                                    this.props.match.params.end,
                                    "YYYY-MM-DD",
                                ).format("DD/MM/YYYY")}
                            </b>
                        </h3>
                    </div>
                </Link>

                <div className="step-2">
                    <div className="daytime-wrapper">
                        <h3>Disponibilità nella stessa fascia oraria</h3>
                        <div className="inputs-wrapper">
                            <TextField
                                id="number"
                                label="N° persone"
                                type="number"
                                defaultValue={1}
                                className="input indexer"
                                variant="outlined"
                                inputProps={{
                                    step: 1,
                                }}
                                onChange={e =>
                                    this.setState({
                                        form: {
                                            ...this.state.form,
                                            index: e.target.value,
                                        },
                                    })
                                }
                            />
                        </div>
                        <h3>Orario lavorativo</h3>
                        <div className="inputs-wrapper">
                            <TextField
                                id="time"
                                label="Apertura"
                                type="time"
                                defaultValue={this.state.form.schedule.opening}
                                className="input"
                                variant="outlined"
                                inputProps={{
                                    step: 300, // 5 min
                                }}
                                onChange={e =>
                                    this.setState({
                                        form: {
                                            ...this.state.form,
                                            schedule: {
                                                ...this.state.form.schedule,
                                                opening: e.target.value,
                                            },
                                        },
                                    })
                                }
                            />

                            <TextField
                                id="time"
                                label="Chiusura"
                                type="time"
                                defaultValue={this.state.form.schedule.closing}
                                className="input"
                                variant="outlined"
                                inputProps={{
                                    step: 300, // 5 min
                                }}
                                onChange={e =>
                                    this.setState({
                                        form: {
                                            ...this.state.form,
                                            schedule: {
                                                ...this.state.form.schedule,
                                                closing: e.target.value,
                                            },
                                        },
                                    })
                                }
                            />
                        </div>
                    </div>

                    <div className="launch-wrapper">
                        <div className="launch-switch">
                            <h3>Pausa pranzo</h3>
                            <Switch
                                className="switch"
                                checked={this.state.form.launch}
                                onChange={e => {
                                    this.setState({
                                        form: {
                                            ...this.state.form,
                                            launch: e.target.checked,
                                        },
                                    });
                                }}
                                color="secondary"
                                name="checkedB"
                            />
                        </div>
                        {this.state.form.launch && (
                            <div className="inputs-wrapper">
                                <TextField
                                    id="time"
                                    label="Chiusura"
                                    type="time"
                                    defaultValue={
                                        this.state.form.schedule.launchClosing
                                    }
                                    className="input"
                                    variant="outlined"
                                    inputProps={{
                                        step: 300, // 5 min
                                    }}
                                    onChange={e =>
                                        this.setState({
                                            form: {
                                                ...this.state.form,
                                                schedule: {
                                                    ...this.state.form.schedule,
                                                    launchClosing:
                                                        e.target.value,
                                                },
                                            },
                                        })
                                    }
                                />
                                <TextField
                                    id="time"
                                    label="Riapertura"
                                    type="time"
                                    defaultValue={
                                        this.state.form.schedule.launchOpening
                                    }
                                    className="input"
                                    variant="outlined"
                                    inputProps={{
                                        step: 300, // 5 min
                                    }}
                                    onChange={e =>
                                        this.setState({
                                            form: {
                                                ...this.state.form,
                                                schedule: {
                                                    ...this.state.form.schedule,
                                                    launchOpening:
                                                        e.target.value,
                                                },
                                            },
                                        })
                                    }
                                />
                            </div>
                        )}
                    </div>

                    <div className="steps-wrapper">
                        <h3>Suddividi le giornate in slot da</h3>

                        <Slider
                            defaultValue={60}
                            valueLabelFormat={valueLabelFormat}
                            aria-labelledby="discrete-slider-restrict"
                            step={5}
                            color="secondary"
                            valueLabelDisplay="auto"
                            marks={marks}
                            min={5}
                            max={120}
                            onChange={(e, val) =>
                                this.setState({
                                    form: {
                                        ...this.state.form,
                                        schedule: {
                                            ...this.state.form.schedule,
                                            steps: val,
                                        },
                                    },
                                })
                            }
                        />
                    </div>
                </div>

                <div className="buttons-wrapper">
                    <Button
                        onClick={() =>
                            this.generateTokens(
                                this.props.match.params.begin,
                                this.props.match.params.end,
                                this.state.form.schedule.opening,
                                this.state.form.schedule.closing,
                                this.state.form.schedule.launchClosing,
                                this.state.form.schedule.launchOpening,
                                this.state.form.schedule.steps,
                            )
                        }
                        variant="contained"
                        color="secondary"
                        disableElevation
                    >
                        Crea tokens
                    </Button>
                    <Link to={"/"}>
                        <Button
                            variant="contained"
                            color="primary"
                            disableElevation
                        >
                            Torna alla Dashboard
                        </Button>
                    </Link>
                </div>
                {/*TODO: Check for already minted token on same dates as new requested ones and warn user about the tokens that will be missed */}
                <Dialog
                    className="creazione-token dialog-wrapper"
                    aria-labelledby="customized-dialog-title"
                    open={this.state.showDialog}
                >
                    <div className="dialog-content">
                        {this.state.slots?.length > 25 ? (
                            <Typography gutterBottom>
                                Non puoi generare più di 25, controlla i dati
                                inseriti.
                            </Typography>
                        ) : (
                            <Typography gutterBottom>
                                Stai per generare {this.state.slots.length}{" "}
                                token prenotazioni da{" "}
                                {this.state.form.schedule.steps} minuti. <br />I
                                token verranno generati dal{" "}
                                {moment(
                                    this.props.match.params.begin,
                                    "YYYY-MM-DD",
                                ).format("DD/MM/YYYY")}{" "}
                                al{" "}
                                {moment(
                                    this.props.match.params.end,
                                    "YYYY-MM-DD",
                                ).format("DD/MM/YYYY")}
                                , dalle {this.state.form.schedule.opening} alle{" "}
                                {this.state.form.schedule.closing}
                                {this.state.form.launch
                                    ? `, con pausa pranzo dalle ${this.state.form.schedule.launchClosing} alle ${this.state.form.schedule.launchOpening}`
                                    : "."}
                            </Typography>
                        )}
                    </div>
                    <div className="buttons-wrapper">
                        <Button
                            onClick={() => this.setState({ showDialog: false })}
                            variant="contained"
                            disableElevation
                            color="secondary"
                        >
                            Indietro
                        </Button>
                        {this.state.slots?.length <= 25 && (
                            <Button
                                onClick={() => {
                                    this.props.startTutorial();
                                    this.reserveSlot(this.state.slots);
                                }}
                                variant="contained"
                                color="primary"
                                disableElevation
                            >
                                Conferma
                            </Button>
                        )}
                    </div>
                </Dialog>
                {/* <Backdrop style={{ zIndex: "1000" }} open={this.state.showLoader}>
          <CircularProgress color="inherit" />
        </Backdrop> */}
                <Spinner show={this.state.showLoader} />
                <Snackbar
                    open={this.state.showSuccess}
                    autoHideDuration={6000}
                    onClose={() => this.setState({ showSuccess: false })}
                >
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={() => this.setState({ showSuccess: false })}
                        severity="success"
                    >
                        Token creati con successo!
                    </MuiAlert>
                </Snackbar>
                <Snackbar
                    open={this.state.showError}
                    autoHideDuration={6000}
                    onClose={() => this.setState({ showError: false })}
                >
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={() => this.setState({ showError: false })}
                        severity="error"
                    >
                        Errore nella creazione dei token!
                    </MuiAlert>
                </Snackbar>
            </div>
        );
    }
}

const mapStateToProps = ({ general }) => ({
    user: general.user,
    userStore: general.userStore,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CreazioneToken);
