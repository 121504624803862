import { createTransform } from "redux-persist";

import { ethers } from "ethers";
import { abiStore } from "../../config/contract";
import { provider } from "../../enhancers/createIpfsEnhancer";

export const transform = createTransform(
    // transform state on its way to being serialized and persisted.
    (inboundState, key) => {
        return {
            ...inboundState,
            // user: inboundState.user.signingKey.mnemonic,
            mnemonic: inboundState.user?.mnemonic?.phrase,
        };
    },

    // transform state being rehydrated
    (outboundState, key) => {
        const user = ethers.Wallet.fromMnemonic(outboundState.mnemonic);
        return {
            ...outboundState,
            user: user,
            userStore: new ethers.Contract(
                outboundState.storeAddress,
                abiStore,
                user.connect(provider),
            ),
        };
    },

    // define which reducers this transform gets called for.
    { whitelist: ["general"] },
);
